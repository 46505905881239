const listaAreaConocimiento = [{
        name: 'emprendimientonegocio',
        descripcion: 'Emprendimiento y Negocios',
        id: 1,
        // con las 3 propiedades del objeto color, se genera el gradiente mediante una funcion. Es necesario primario y secundario para colores asociados al área (Botones, titulos)
        color: {
            primary: '#FFB92E',
            secondary: '#ffae04',
            gradientAngle: 45
        }
    },
    {
        name: 'liderazgo',
        descripcion: 'Liderazgo',
        id: 2,
        color: {
            primary: '#C10000',
            secondary: '#f64600',
            gradientAngle: 45
        }
    },
    {
        name: 'comunicacion',
        descripcion: 'Comunicación',
        id: 3,
        color: {
            primary: '#354CF9',
            secondary: '#354CF9',
            gradientAngle: 45
        }
    },
    {
        name: 'desarrollopersonal',
        descripcion: 'Crecimiento Personal',
        id: 4,
        color: {
            primary: '#8AC445',
            secondary: '#2d6b00',
            gradientAngle: 45
        }
    },
    {
        name: 'desarrollofisico',
        descripcion: 'Bienestar y Salud',
        id: 5,
        color: {
            primary: '#FC3A72',
            secondary: '#770005',
            gradientAngle: 45
        }
    },
    {
        name: 'conocimientotecnico',
        descripcion: 'Conocimiento Técnico',
        id: 6,
        color: {
            primary: '#40CCB1',
            secondary: '#11b29c',
            gradientAngle: 45
        }
    }
]
export default listaAreaConocimiento