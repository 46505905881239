/* eslint-disable no-console */
const apiVimeo = async (idVideo) => {
	let response = await fetch(
		`https://api.vimeo.com/videos/${idVideo}/pictures`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer 1858c7c3d68efa58e60684d9ca38c8e0',
			},
		},
	);
	return response.json();
};

export default apiVimeo;
