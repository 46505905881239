<template>
  <v-navigation-drawer
    style="color: white !important; z-index: 8"
    color="primary"
    right
    v-model="primaryDrawer.model"
    :clipped="primaryDrawer.clipped"
    :floating="true"
    temporary
    app
    overflow
    :disable-route-watcher="false"
  >
    <v-list class="py-0 listMenu" dense nav style="color: white !important">
      <v-list-item class="text-right">
        <label class="text-right" @click="primaryDrawer.model = false">
          <v-icon right> mdi-close-thick </v-icon>
        </label>

        <!-- <v-list-item-avatar right @click="primaryDrawer.model = false">
        </v-list-item-avatar> -->
        <!-- <v-list-item-content>
          <v-list-item-title>  </v-list-item-title>
        </v-list-item-content> -->
      </v-list-item>
      <v-list-item two-line style="color: white !important">
        <v-list-item-avatar>
          <v-icon> mdi-account </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> Hola </v-list-item-title>
          <v-list-item-subtitle>
            {{ firstname() }}
            {{ firstsurname() }}
            {{ secondsurname() }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon> mdi-account </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Tu usuario: </v-list-item-title>
          <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
          <v-list-item-subtitle>
            {{ username() }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon> mdi-email-edit </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Tu email registrado: </v-list-item-title>
          <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
          <v-list-item-subtitle>
            {{ email() }}
          </v-list-item-subtitle>
          <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon> mdi-account </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Folio o Id Teoma: </v-list-item-title>
          <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
          <v-list-item-subtitle>
            {{ customerid() }}
          </v-list-item-subtitle>
          <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon style="color: white !important"> mdi-key-change </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link to="/change" replace style="color: white !important">
              Cambiar Contraseña
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon> mdi-account-off-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <button @click="cerrarSesion">Cerrar Sesión</button>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-icon>
          <v-icon> mdi-account-edit </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link
              :to="`/yo/${this.$storeNiu.getTextOrInt('user')}`"
              replace
            >
              Mis datos
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important" v-if="team() != ''">
        <v-list-item-icon>
          <v-icon> mdi-email-edit </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title></v-list-item-title>
          <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
          <v-list-item-subtitle>
            {{ team() }}
          </v-list-item-subtitle>
          <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important">
        <v-list-item-content>
          <v-list-item-title>
            <v-btn
              target="_blank"
              dark
              href="https://niufleex.com/terminos-y-condiciones/"
            >
              Terminos y Condiciones
            </v-btn>
          </v-list-item-title>
          <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
          <v-list-item-subtitle>
            <v-btn
              dark
              target="_blank"
              href="https://niufleex.com/politicas-de-privacidad/"
            >
              Polícitas de Privacidad
            </v-btn>
          </v-list-item-subtitle>
          <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon> mdi-new-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link
              :to="`/explora/free`"
              style="
								color: white !important;
								background-color: #de7409;
								border-radius: 8px;
								padding: 13px;
								text-decoration: none;
							"
            >
              Versión Beta Preview
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="color: white !important" v-if="obtenerCodigo">
        <v-list-item-icon>
          <v-icon> mdi-gift-open-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <!-- <v-text-field label="Email"
            placeholder="Email regalo" v-model="emailregalo" clearable></v-text-field> -->
            <v-btn @click="enviarregalo">Obtener Código</v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="color: white !important" v-if="urlcode">
        <v-list-item-icon @click="copiar">
          <v-icon> mdi-content-copy </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a :href="urlcode" id="code_share">{{ urlcode }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-alert v-model="alert.active" :timeout="3000" outlined color="purple">
      <div>Copiado el link</div>
    </v-alert>
  </v-navigation-drawer>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  props: {
    primaryDrawer: {
      type: Object,
      default: function () {
        return {
          model: null,
          clipped: false,
          floating: false,
          mini: false,
          urlcode: null
        }
      }
    }
  },
  created () {
    this.data = this.$storeNiu.getObjectValue('data') || {
      firstname: '',
      firstsurname: '',
      secondsurname: '',
      email: '',
      username: ''
    }
  },
  computed: {
    ...mapState(['modalShowQuiz', 'modalContentQuiz'])
  },
  methods: {
    ...mapMutations(['SET_ALERT', 'SET_MODALSHOWQUIZ', 'SET_MODALCONTENTQUIZ']),
    encuesta () {
      // try {
      //   let config = {
      //     headers: {
      //       Authorization: `Bearer ${this.$storeNiu.getTextOrInt('token') ||
      //         this.token}`
      //     }
      //   }
      //   this.$http
      //     .post('/Encuestas/getEncuestaGeneral', config)
      //     .then(encuesta => {
      //       // eslint-disable-next-line no-console
      //       console.log(encuesta)
      //       if (
      //         encuesta &&
      //         encuesta.data &&
      //         encuesta.data.questions &&
      //         encuesta.data.questions.length > 0
      //       ) {
      //         this.SET_MODALSHOWQUIZ(true)
      //         this.SET_MODALCONTENTQUIZ(encuesta.data)
      //       }
      //     })
      //   // eslint-disable-next-line no-empty
      // } catch (error) {}
    },
    async showButton () {
      try {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$storeNiu.getTextOrInt('token') ||
              this.token}`
          }
        }
        let show = (
          await this.$http.post('/users/showButtonShareMembresia', config)
        ).data

        this.obtenerCodigo = show
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },

    copiar () {
      // eslint-disable-next-line no-console
      // console.log(document.getElementById(id), id)
      var aux = document.createElement('div')
      aux.setAttribute('contentEditable', true)
      aux.innerHTML = document.getElementById('code_share').innerHTML
      aux.setAttribute(
        'onfocus',
        "document.execCommand('selectAll',false,null)"
      )
      document.body.appendChild(aux)
      aux.focus()
      document.execCommand('copy')
      document.body.removeChild(aux)
      this.alert.active = true

      setTimeout(() => {
        this.alert.active = false
      }, 3000)
      this.SET_ALERT({
        message: 'Link Copiado',
        type: 'success',
        snackbar: true
      })
    },
    async enviarregalo () {
      let codigos = (await this.$http.post(`/registro/getCodePresentShare`))
        .data[0]

      // eslint-disable-next-line no-console
      console.log(codigos)

      this.urlcode = `https://registro.niufleex.com/registro/${codigos.code_acceso}/${codigos.code_orden}`
    },
    firstname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')
        // this.showButton();
        // this.encuesta();
        if (Object.prototype.hasOwnProperty.call(data, 'firstname')) {
          return data.firstname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    genero () {
      try {
        let data = this.$storeNiu.getObjectValue('data')
        // this.showButton();
        // this.encuesta();
        if (Object.prototype.hasOwnProperty.call(data, 'genero')) {
          return data.genero
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    firstsurname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'firstsurname')) {
          return data.firstsurname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    secondsurname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'secondsurname')) {
          return data.secondsurname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    username () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'username')) {
          return data.username
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    email () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'email')) {
          return data.email
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    customerid () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'customer_id')) {
          return data.customer_id
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    team () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'team_title')) {
          return data.team_title
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    cerrarSesion () {
      this.$emit('menufloat')
      this.$storeNiu.clear()
      this.$router.push('/login?logout=true')
      // window.location.href = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_LOGIN}?logout=true`;
      // window.location.href = `${process.env.VUE_APP_PROTOCOL}://app.niufleex.com?logout=true`;
    }
  },
  data () {
    return {
      urlcode: null,
      items: [],
      alert: { active: false },
      obtenerCodigo: false,
      data: {
        firstname: '',
        firstsurname: '',
        secondsurname: '',
        email: '',
        username: ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$fondo: white;
.niu-dark {
  background-color: $fondo !important;
}
.listMenu,
.listMenu * {
  color: white !important;
}
</style>
