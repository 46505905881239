<template>
  <v-row>
    <v-col>
      <v-row v-if="videos.length > 0">
        <v-col>
          <h3>
            Sesión {{ videoSeleccionado.videoOrden }}:
            {{ videoSeleccionado.videoTitle }}
          </h3>
          <div class="niu-content-iconos">
            <div class="niu-iconos">
              <label>
                <v-icon style="color: #717171;">mdi-clock</v-icon>
                Duración: {{ videoSeleccionado.videoDuracion }} minutos
              </label>
              <label>
                <v-icon style="color: #717171;">mdi-chat-processing</v-icon>
                Idioma: {{ videoSeleccionado.videoIdioma || 'Español' }}
              </label>
              <label>
                <v-icon style="color: #717171;">mdi-bookmark-multiple</v-icon>
                Área de Conocimiento:
                {{ videoSeleccionado.videoAreaconocimientoTitle }}
              </label>
            </div>
          </div>
          <v-btn
            v-if="showButtonOpenList && loadCarrusel.isMasterClass == 0"
            @click="showOpenList = true"
            color="primary"
          >
            Track Completo
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" sm="8" v-resize="onResize">
          <v-row>
            <v-col style="position:relative">
              <!-- <iframe
                ref="framevimeo"
                id="framevimeo"
                :src="
                  `https://player.vimeo.com/video/${this.videoSeleccionado.videoIdVideoVimeo}?texttrack=es-419`
                "
                width="100%"
                :height="`${heightFrame}px`"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe> -->

              <div
                width="100%"
                :height="`${heightFrame}px`"
                id="videomodulosesiones"
                ref="videomodulosesiones"
                class="ma-0 pa-0"
                style="width:100%"
              ></div>
              <div
                v-show="divEndSesion"
                style="position:absolute;width:100%; top:0; "
                width="100%"
                :height="`${heightFrame}px`"
              >
                <v-row justify="center">
                  <v-col cols="10">
                    <v-row justify="center">
                      <v-col cols="3">
                        <v-progress-circular
                          :rotate="180"
                          :size="100"
                          :width="15"
                          :value="segundos"
                          color="purple"
                          indeterminate
                        >
                          {{ segundos }}
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn @click="reiniciarSiguienteSesion">
                          Detener
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn @click="siguienteSesion" light color="primary">
                          Continuar con la siguiente sesión
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Sobre la Sesión:</h3>
              <p>
                {{ videoSeleccionado.videoDescription }}
              </p>
              <h3>¿Qué aprenderás?</h3>
              <p>
                {{ videoSeleccionado.videoQueAprenderas }}
              </p>
              <p>
                <strong>Conceptos Claves: </strong>
                <v-chip
                  v-for="concepto in videoSeleccionado.videoConceptosClaves
                    ? videoSeleccionado.videoConceptosClaves.split(',')
                    : []"
                  :key="concepto.trim()"
                >
                  {{ concepto.trim().replace('-', '') }}
                </v-chip>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-row>
            <v-col>
              <v-card>
                <v-tabs v-model="tab_modulo" centered light icons-and-text>
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Sesiones
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab_modulo">
                  <v-tab-item value="tab-1">
                    <v-card flat light>
                      <v-card-text>
                        <v-row
                          v-for="(video, index) in videos"
                          :key="'video_' + video.id + index"
                        >
                          <v-col cols="4" sm="12" md="4">
                            <v-img
                              dark
                              @click="changeVideoSeleccionado(video)"
                              :aspect-ratio="16 / 9"
                              max-width="150px"
                              :src="imgVimeo(video)"
                              lazy-src="../../assets/client-loader.gif"
                            >
                              <div
                                style="position:absolute; top:45%; left:45%;"
                              >
                                <v-icon>mdi-player</v-icon>
                              </div>
                              <p
                                style="color:#FFF; background-color: rgba(0,0,0,0.4); width:100%; height: 100%;"
                                class="d-none d-sm-flex d-md-none pa-2"
                              >
                                {{ video.videoOrden }}.-
                                {{ video.videoTitle }}
                              </p>
                            </v-img>
                          </v-col>
                          <v-col>
                            <p style="color:#000;" class="d-none d-md-flex">
                              {{ video.videoOrden }}.-
                              {{ video.videoTitle }}
                            </p>
                            <p style="color:#000;" class="d-flex d-sm-none">
                              {{ video.videoOrden }}.-
                              {{ video.videoTitle }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="
              videoSeleccionado.contenidos
                ? videoSeleccionado.contenidos.length
                : 0 > 0
            "
          >
            <v-col>
              <h4>
                POPFLEEX
                <img
                  v-if="isPremium == 1"
                  src="../../assets/logo_premium_150x150.png"
                  style="height: 22px;"
                  alt="Ícono Premium"
                />
              </h4>
              <h5>Contenido descargables y complementarios de la sesión</h5>
              <span v-if="false">{{ isPremium }} {{ membresia }}</span>
              <div
                v-for="(item, i) in videoSeleccionado.contenidos"
                :key="`imagen_vimeo${i}`"
              >
                <v-btn
                  v-if="(isPremium == 1 && membresia == 2) || isPremium == 0"
                  target="_blank"
                  :href="item.contenido"
                  outlined
                  light
                >
                  <v-icon dark> {{ item.icon }} </v-icon> {{ item.name }}
                </v-btn>

                <v-btn
                  v-else
                  target="_blank"
                  @click="activaModelPremium"
                  outlined
                  light
                >
                  <v-icon dark> {{ item.icon }} </v-icon> {{ item.name }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-bottom-sheet v-model="showOpenList">
            <v-row style="background-color:white" justify="end">
              <v-col cols="1">
                <v-icon @click="closeOpenList">
                  x
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="background-color:white" class="pa-1">
                <CarrouselModulos :infoTrack="infoTrack"></CarrouselModulos>
              </v-col>
            </v-row>
          </v-bottom-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
// eslint-disable-next-line no-unused-vars
let videoplayer = {}
import { mapState, mapMutations } from 'vuex'
import CarrouselModulos from '../explora/carruselEVA_Modulos.vue'
import apiVimeo from '../../services/apiVimeo'

export default {
  components: {
    CarrouselModulos
  },
  props: {
    videos: {
      type: Array,
      default: []
    },
    isPremium: Number
  },
  async created () {
    // console.log(this.isPremium)

    this.videoSeleccionado = this.videos[0]
    for (let index = 0; index < this.videos.length; index++) {
      let uno = (await apiVimeo(this.videos[index].videoIdVideoVimeo)).data
      uno = uno
        .find(ele => ele.active == true)
        .sizes.find(ele => ele.width == 1280).link
      this.$set(this.videos[index], 'vimeo', uno)
    }

    // console.log(this.videos, this.$route.params.track, this.$refs.framevimeo)

    this.loadCarrusel = await this.$http.post('tracks/tracksniufleex', {
      trackCode: this.$route.params.track
    })
    // console.log(loadCarrusel)
    if (this.loadCarrusel.data.length > 0) {
      this.showButtonOpenList = true
      this.loadCarrusel = this.loadCarrusel.data[0]
      console.group('loadCarrusel')
      console.log(this.loadCarrusel)
      console.groupEnd('loadCarrusel')
      this.SET_INFOTRACK({
        trackTitle: this.loadCarrusel.trackTitle,
        trackCode: this.loadCarrusel.trackCode,
        trackId: this.loadCarrusel.trackId,
        trackAreaconocimientoCode: this.loadCarrusel.trackAreaconocimientoCode,
        trackProgreso: this.loadCarrusel.trackProgreso,
        isPremium: this.loadCarrusel.isPremium,
        modulos: []
      })
    }
  },
  mounted () {
    videoplayer = new this.$Player('videomodulosesiones', {
      id: this.videoSeleccionado.videoIdVideoVimeo,
      width: this.widthFrame,
      height: this.heightFrame
    })
    videoplayer.on('loaded', () => {
      videoplayer.enableTextTrack('es-419')
      videoplayer.on('play', () => {
       this.methodsSetIntervalCurrentTime()
      })
    })
    console.group('mounted')
    console.log(this.videoSeleccionado.videoOrden + 1)
    console.groupEnd('mounted')

    // videoplayer.on('ended', () => {
    //   this.activarFind()
    // })
    // this.activarFind()
  },
  methods: {
    ...mapMutations(['SET_INFOTRACK']),
    activarFind () {
      this.divEndSesion = true
      let pos = this.videoSeleccionado.videoOrden + 1

      if (pos < this.videos.length) {
        this.interval = setInterval(() => {
          console.log('Paso')
          if (this.segundos === 0) {
            this.siguienteSesion()
          }
          this.segundos -= 1
        }, 1000)
      }
    },
    activaModelPremium () {
      this.$emit('OpenDialogModuloPremium')
    },
    imgVimeo (video) {
      return video.vimeo
    },
    closeOpenList () {
      this.showOpenList = false
    },
    showVideoFunction (idvideo, code) {
      if (idvideo && code) {
        this.$router.push({
          name: 'sesion',
          params: {
            id: idvideo,
            code: code
          }
        })
      }
    },
    async cambiar (id) {
      this.idseleccionado = id.idvideo
    },
    async changeVideoSeleccionado (video) {
      try {
        if (video.length > 0) {
          this.videoSeleccionado = video[0]
          console.log(this.videoSeleccionado)
          await videoplayer
            .loadVideo(this.videoSeleccionado.videoIdVideoVimeo)
            .then(() => {
              videoplayer.play()
              videoplayer.enableTextTrack('es-419')
              // videoplayer.on('ended', () => {
              //   this.activarFind()
              // })
            })
        } else {
          this.videoSeleccionado = video
          console.log(this.videoSeleccionado)
          await videoplayer
            .loadVideo(this.videoSeleccionado.videoIdVideoVimeo)
            .then(() => {
              videoplayer.play()
              // videoplayer.on('ended', () => {
              //   this.activarFind()
              // })
            })
        }
        console.log(this.videoSeleccionado)
      } catch (error) {
        clearInterval(this.interval)
      }
    },
    siguienteSesion () {
      let pos = this.videoSeleccionado.videoOrden + 1
      this.changeVideoSeleccionado(
        this.videos.filter(ele => {
          return ele.videoOrden == pos
        })
      )
      this.reiniciarSiguienteSesion()
    },
    reiniciarSiguienteSesion () {
      clearInterval(this.interval)
      this.divEndSesion = false
      this.segundos = 3
    },
    onResize () {
      let div = document.querySelector('#videomodulosesiones')
      this.heightFrame = ((div.offsetWidth - 50) * 9) / 16
      this.widthFrame = div.offsetWidth
      try {
        div.querySelector('iframe').setAttribute('width', this.widthFrame)
      } catch (error) {}
      try {
        div.querySelector('iframe').setAttribute('height', this.heightFrame)
      } catch (error) {}
    },
    methodsSetIntervalCurrentTime () {
      this.setIntervalCurrentTime =  setInterval(async() => {
        console.log('Paso')
        let time=0
        time=await videoplayer.getCurrentTime()
        console.log(time)
      }, 20000)
    }
  },

  updated () {},
  computed: {
    ...mapState('carruseles', ['modulo', 'videonumber']),
    ...mapState(['infoTrack', 'membresia']),
    btnAreaConocimiento () {
      return {
        'niu-background-gradient-emprendimientonegocio':
          this.modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-gradient-liderazgo':
          this.modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-gradient-desarrollofisico':
          this.modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-gradient-desarrollopersonal':
          this.modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-gradient-conocimientotecnico':
          this.modulo.areaconocimientoCode == 'conocimientotecnico',
        'niu-background-gradient-comunicacion':
          this.modulo.areaconocimientoCode == 'comunicacion'
      }
    },
    imageHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '800px'
        case 'sm':
          return '1000px'
        case 'md':
          return '500px'
        case 'lg':
          return '600px'
        case 'xl':
          return '600px'
      }
    },
    nameBreakpoint () {
      return this.$vuetify.breakpoint.name
    },
    altoCarruselVideo () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '1300px'
        case 'sm':
          return '1200px'
        case 'md':
          // return 16 / 9
          return '800px'
        case 'lg':
          // return 16 / 9
          return '800px'
        case 'xl':
          // return 16 / 9
          return '800px'
      }
    },
    aspectoRatio () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 4 / 3
        case 'sm':
          return 4 / 3
        case 'md':
          // return 16 / 9
          return 3 / 4
        case 'lg':
          // return 16 / 9
          return 4 / 3
        case 'xl':
          // return 16 / 9
          return 4 / 3
      }
    },
    classBarraVerticalModulo () {
      return {
        'niu-background-gradient-emprendimientonegocio':
          this.modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-gradient-liderazgo':
          this.modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-gradient-desarrollofisico':
          this.modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-gradient-desarrollopersonal':
          this.modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-gradient-conocimientotecnico':
          this.modulo.areaconocimientoCode == 'conocimientotecnico',
        'niu-background-gradient-comunicacion':
          this.modulo.areaconocimientoCode == 'comunicacion'
      }
    },
    moduloSeleccionado () {
      return this.$store.state.moduloSeleccionado
    },
    vinetasAreaConocimiento () {
      return require('../../assets/vinetas/' +
        this.modulo.areaconocimientoCode +
        '.png')
    },
    fullscreen () {
      this.screen = ''
      return this.screen
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  data () {
    return {
      setIntervalCurrentTime: null,
      interval: null,
      segundos: 3,
      divEndSesion: false,
      tab_modulo: null,
      showButtonOpenList: false,
      showOpenList: false,
      vimeo: [],
      showVideo: false,
      showCarousel: true,
      videoSeleccionado: {},
      screen: '',
      ordenVideoActivar: 1,
      videoplayer: '',
      test: [{ idvideo: 423440615 }, { idvideo: 418757076 }],
      idseleccionado: {},
      listVideo: 0,
      heightFrame: '400',
      widthFrame: '600',
      model: null,
      loadCarrusel: null
    }
  }
  // beforeDestroy () {

  //   try {
  //     this.videoplayer.destroy()
  //     // clearInterval(this.popfleex)
  //   } catch (error) {
  //   }
  // }
}
</script>
<style lang="scss" scoped>
$bg_page: #f5f5f5;
.niu-bg-page {
  background-color: $bg_page !important;
}
.niu-list-videos {
  background-color: $bg_page !important;
}
.niu-content-iconos {
  color: black;
  font-size: 35px;
  display: inline-block;
  width: 90%;
  position: relative;
  left: 55px;
  font-weight: bold;
  word-break: keep-all;
}
.niu-iconos {
  font-size: 1.2rem;
  font-weight: normal;
  color: #717171;
}
.niu-label {
  display: inline-block;
  border-radius: 10px;
  line-height: 5px;
  padding: 10px;
}
.niu-title-modulo {
  color: black;
  font-size: 35px;
  display: inline-block;
  width: 50%;
  position: relative;
  left: 55px;
  font-weight: bold;
}
.niu-color-dark {
  cursor: context-menu !important;
}
@media (min-width: 959px) {
  .v-application--is-ltr .v-window__next {
    right: -2%;
  }
  .v-application--is-ltr .v-window__prev {
    left: -2%;
  }
}
@media (min-width: 959px) {
  .v-application--is-ltr .v-window__next {
    right: 50% !important;
  }
}

@media (max-width: 1264px) and (min-width: 960px) {
  .v-window__prev {
    left: -20px !important;
  }
}
.niu-vineta {
  position: absolute;
  top: 0;
  float: left;
}
.niu-icon-play {
  position: relative;
  font-size: 230px !important;
  border-radius: 50%;
  border: 2px solid #fff;
  transform: translate(0%, 40%) !important;
}
.niu-icon-play:hover {
  border: 5px solid #590d53;
  color: #590d53;
}
.v-window__prev,
.v-window__next {
  top: 10px !important;
}
@media (max-width: 600px) {
  .niu-icon-play {
    font-size: 150px !important;
  }
  .v-window__prev,
  .v-window__next {
    top: 10px !important;
  }
  .v-window__prev {
    left: -25px !important;
  }
  .v-window__next {
    right: -25px !important;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .niu-icon-play {
    font-size: 250px !important;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .niu-icon-play {
    font-size: 190px !important;
    transform: translate(0%, 70%) !important;
  }
  .v-window__prev,
  .v-window__next {
    top: 145px !important;
  }
  .v-window__prev {
    left: -10px !important;
  }
}
@media (min-width: 960px) and (max-width: 980px) {
  .v-window__next {
    right: 456px !important;
  }
}
@media (min-width: 980px) and (max-width: 1000px) {
  .v-window__next {
    right: 456px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .v-window__next {
    right: 510px !important;
  }
}
@media (min-width: 1050px) and (max-width: 1150px) {
  .v-window__next {
    right: 518px !important;
  }
}
@media (min-width: 1150px) and (max-width: 1200px) {
  .v-window__next {
    right: 552px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1264px) {
  .v-window__next {
    right: 585px !important;
  }
}

@media (min-width: 1264px) and (max-width: 1904px) {
  .niu-icon-play {
    font-size: 220px !important;
    transform: translate(0%, 20%) !important;
  }
  .v-window__prev,
  .v-window__next {
    top: 145px !important;
  }
  .v-window__prev {
    left: -10px !important;
  }
}
@media (min-width: 1264px) and (max-width: 1400px) {
  .v-window__next {
    right: 615px !important;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .v-window__next {
    right: 745px !important;
  }
}
@media (min-width: 1600px) and (max-width: 1904px) {
  .v-window__next {
    right: 650px !important;
  }
}
@media (min-width: 1904px) {
  .v-window__prev,
  .v-window__next {
    top: 145px !important;
  }
  .v-window__next {
    right: 590px !important;
  }
  .v-window__prev {
    left: -10px !important;
  }
}
.niu-break-word {
  word-break: break-word !important;
}
.niu-font-size-34 {
  font-size: 34px !important;
}
.niu-barra-vertical-video {
  position: absolute;
  height: 100%;
  width: 6px;
  top: 0px;
  background-color: transparent;
  right: -50px;
  border-radius: 20px;
}
.niu-barra-vertical-video-2 {
  position: absolute;
  height: 92%;
  width: 6px;
  top: 8%;
  background-color: transparent;
  left: 0;
  border-radius: 20px;
}
.niu-titulo-video-modulo {
  font-size: 40px !important;
}
.niu-descripcion-video-modulo {
  font-size: 20px !important;
}
.niu-z-index-3 {
  z-index: 3;
}
.niu-z-index-2 {
  z-index: 2;
}
.niu-z-index-1 {
  z-index: 1;
}
.niu-col-vineta {
  height: 90px;
}
.niu-top-menos-30 {
  top: -30px;
}
.niu-vineta {
  opacity: 0.3;
  z-index: 1;
  top: 15px;
}

.niu-vineta.active {
  z-index: 2;
  opacity: 1;
  top: 0px;
}
.niu-vineta.niu-sesion1 {
  left: 0px;
}
.niu-vineta.niu-sesion2 {
  left: 30px;
}
.niu-vineta.niu-sesion3 {
  left: 60px;
}
#videomodulosesiones > iframe {
  position: relative;
}
</style>
