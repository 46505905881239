<template>
	<v-row class="mt-15">
		<v-col cols="5" sm="3" md="4" lg="3">
			<v-list dense class="grey lighten-4" disabled light>
				<v-row justify="center" align="center">
					<v-col cols="12">
						<h4>Mis datos</h4>
					</v-col>
				</v-row>
				<v-list-item>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-account</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="grey--text"> </v-list-item-title>
						<v-list-item-title>Tu usuario</v-list-item-title>
						<v-list-item-subtitle>{{ form.username }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-credit-card-marker-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Tu sucripción vigente</v-list-item-title>
						<v-list-item-subtitle>{{ form.membership }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-lock-open-variant</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Fecha de Activación</v-list-item-title>
						<v-list-item-subtitle>{{
							form.fecha_activacion
						}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-lock-open-alert</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Fecha de vencimiento</v-list-item-title>
						<v-list-item-subtitle>{{
							form.fecha_termino
						}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-table-clock</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Días restantes</v-list-item-title>
						<v-list-item-subtitle>
							{{ form.dias_restantes }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>
			<v-list light>
				<v-list-item link @click="dialogHistorial = true">
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-text-box-check-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<div class="pa-1 activo">Activo</div>
						<v-list-item-title>Historial</v-list-item-title>
						<v-list-item-subtitle>Membresías</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<historial-membresias
					:dialog="dialogHistorial"
					:historial="registroHistorial"
					@closeDialog="closeDialogHistorial"
				></historial-membresias>
				<v-list-item link @click="openDialogRenueva">
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-cog-clockwise</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<div class="pa-1 activo">Activo</div>
						<v-list-item-title>Renuevate</v-list-item-title>
						<v-list-item-subtitle>Renovación anticipada</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<renueva-membresia
					:dialog="dialogRenueva"
					@closeDialogRenueva="closeDialogRenueva"
					:alertErrorConexion="alertErrorConexion"
					@showAlert="showAlert"
				></renueva-membresia>

				<v-list-item
					v-if="form.username.includes('facebook')"
					:data-username="form.username"
					link
					@click="openDialogEliminarCuenta"
				>
					<v-list-item-action class="d-sm-none d-none d-md-flex">
						<v-icon>mdi-cog-clockwise</v-icon>
					</v-list-item-action>
					<v-list-item-content> Elimina tu cuenta </v-list-item-content>
				</v-list-item>
				<EliminarCuenta
					:dialog="dialogEliminarCuenta"
					@closeDialogEliminarCuenta="closeDialogEliminarCuenta"
					:alertErrorConexion="alertErrorConexion"
					@showAlert="showAlert"
				></EliminarCuenta>
			</v-list>
		</v-col>
		<v-col cols="7" sm="9" md="8" lg="9" justify="center">
			<v-form style="max-width: 500px" @submit.prevent="enviarDatos" light>
				<v-text-field
					light
					label="Nombre"
					placeholder="ej: Juan"
					filled
					color="#ffffff"
					v-model="form.firstname"
					:rules="usuarioRules"
					required
				></v-text-field>
				<v-text-field
					light
					label="Apellido"
					placeholder="ej: Pérez"
					filled
					color="#ffffff"
					v-model="form.firstsurname"
					:rules="usuarioRules"
					required
				></v-text-field>
				<v-text-field
					light
					label="Email"
					placeholder="ej: algun@correo.com"
					filled
					color="#ffffff"
					v-model="form.email"
					:rules="emailRules"
					required
				></v-text-field>
				<v-dialog
					light
					ref="dialogFechaNac"
					v-model="modal"
					:return-value.sync="form.fechanac"
					persistent
					width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							light
							v-model="form.fechanac"
							label="Fecha de Nacimiento"
							filled
							color="#ffffff"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						light
						v-model="form.fechanac"
						scrollable
						:allowed-dates="testDataFechaNac"
					>
						<v-spacer></v-spacer>
						<v-btn light text color="primary" @click="modal = false">
							Cancel
						</v-btn>
						<v-btn
							light
							text
							color="primary"
							@click="$refs.dialogFechaNac.save(form.fechanac)"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-dialog>
				<v-text-field
					light
					label="Número Celular"
					v-model="form.fono"
					filled
					type="text"
					:rules="rules.fono"
				/>
				<!-- <v-row>
              <v-col cols="4">
                <v-select
                  color="#ffffff"
                  filled
                  :items="codigos"
                  label="Cód. País"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  label="Fono"
                  v-model="form.fono"
                  color="#ffffff"
                  filled
                  type="text"
                />
              </v-col>
            </v-row> -->
				<v-combobox
					light
					v-model="form.pais"
					:items="nacionalidad"
					label="País de residencia"
					filled
					color="#ffffff"
					:rules="rules.nacionalidad"
				></v-combobox>
				<v-radio-group light v-model="form.genero" row :rules="rules.genero">
					<v-radio label="Mujer" value="mujer"></v-radio>
					<v-radio label="Hombre" value="hombre"></v-radio>
					<v-radio
						label="No quiero especificar"
						value="sin-especificar"
					></v-radio>
				</v-radio-group>
				<v-btn @click="actualizar" light> Actualizar </v-btn>
			</v-form>
		</v-col>
	</v-row>
</template>
<script>
import moment from "moment";
import nacionalidades from "../services/nacionalidades";
import HistorialMembresias from "../components/yo/historial_membresias.vue";
import RenuevaMembresia from "../components/yo/renueva_membresia.vue";
import EliminarCuenta from "../components/yo/eliminar_cuenta.vue";
export default {
	components: {
		HistorialMembresias,
		RenuevaMembresia,
		EliminarCuenta,
	},
	methods: {
		openDialogEliminarCuenta() {
			if (this.alertErrorConexion) {
				this.showAlert();
			} else {
				this.dialogEliminarCuenta = true;
			}
		},
		openDialogRenueva() {
			if (this.alertErrorConexion) {
				this.showAlert();
			} else {
				this.dialogRenueva = true;
			}
		},
		hideAlert() {
			this.$store.commit("alert", {
				type: "",
				message: "",
				snackbar: false,
			});
		},
		showAlert() {
			this.$store.commit("alert", {
				type: "#F00",
				message: "Revise su conexión a Internet o recargue la página",
				snackbar: true,
				timeout: -1,
				equis: false,
				top: false,
			});
		},
		closeDialogHistorial() {
			this.dialogHistorial = false;
		},
		closeDialogEliminarCuenta() {
			this.dialogEliminarCuenta = false;
		},
		closeDialogRenueva({ actualizarHistorial, dialog }) {
			this.dialogRenueva = dialog;
			if (actualizarHistorial) {
				this.cargarHistorial();
			}
		},
		async cargarHistorial() {
			this.registroHistorial = (
				await this.$http.post("users/historialSuscripciones", {
					username: this.$storeNiu.getTextOrInt("user"),
				})
			).data;
		},
		actualizar() {
			this.$http
				.post("users/update", { ...this.form })
				//eslint-disable-next-line
				.then((response) => {
					this.$store.commit("alert", {
						type: "success",
						message: "Datos Actualizados",
						snackbar: true,
					});
				})
				//eslint-disable-next-line
				.catch((err) => {});
		},
		enviarDatos() {},
		testDataFechaNac(fecha) {
			if (Date.parse(fecha) <= new Date()) {
				return true;
			}
			return false;
		},
	},
	async created() {
		window.addEventListener("offline", () => {
			// eslint-disable-next-line no-console
			console.log("offline");
			this.showAlert();
			this.alertErrorConexion = true;
		});
		window.addEventListener("online", () => {
			// eslint-disable-next-line no-console
			console.log("online");
			this.hideAlert();
			this.alertErrorConexion = false;
		});
		this.nacionalidad = nacionalidades;

		this.$http
			.post("users/datoDelUsuario", {
				username: this.$storeNiu.getTextOrInt("user"),
			})
			.then((response) => {
				let datos = {
					...response.data,
					fechanac:
						response.data.fechanac.substr(0, 10) != "0001-01-01"
							? moment().format(response.data.fechanac.substr(0, 10))
							: null,
					fecha_activacion:
						response.data.fecha_activacion.substr(0, 10) != "0001-01-01"
							? moment().format(response.data.fecha_activacion.substr(0, 10))
							: null,
					fecha_termino:
						response.data.fecha_termino.substr(0, 10) != "0001-01-01"
							? moment().format(response.data.fecha_termino.substr(0, 10))
							: null,
				};
				this.form = datos;
				// this.fecha= moment().format('3000-08-22')
				// this.fecha=this.form.fechanac.substr(0, 10)
				//eslint-disable-next-line
				console.log(this.form);
			})
			//eslint-disable-next-line
			.catch((err) => {});
	},
	async mounted() {
		await this.cargarHistorial();
		//eslint-disable-next-line
		console.log(this.$route);
		this.$nextTick(() => {
			if (
				this.form.dias_restantes < 0 ||
				this.$route.query["renueva"] == "membresia"
			) {
				this.dialogRenueva = true;
			}
		});
	},
	destroyed() {
		try {
			window.removeEventListener("offline");
			window.removeEventListener("online");
			// eslint-disable-next-line no-empty
		} catch (error) {}
	},
	data: () => {
		return {
			alertErrorConexion: false,
			registroHistorial: [],
			dialogEliminarCuenta: false,
			dialogHistorial: false,
			dialogRenueva: false,
			dialog: false,
			drawer: null,
			fecha: null,
			nacionalidad: [],
			form: {
				username: "",
				firstname: "",
				middlename: "",
				firstsurname: "",
				secondsurname: "",
				email: "",
				fono: "",
				pais: "",
				nrocompra: "",
				date: "",
				genero: "",
				fechanac: "",
			},
			usuarioRules: [
				(v) => !!v || "Usuario requerido",
				(v) => (v && v.length <= 30) || "Excede el límite de caracteres",
			],
			emailRules: [
				(v) => !!v || "E-mail Requerido",
				(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
			],
			rules: {
				code: [
					(v) => !!v || "Usuario requerido",
					(v) => (v && v.length <= 100) || "Excede el límite de caracteres",
					(v) => (v && v.length >= 10) || "Código no válido",
				],
				nrocompra: [
					(v) => !!v || "Usuario requerido",
					(v) => (v && v.length <= 7) || "Excede el límite de caracteres",
					(v) => (v && v.length >= 5) || "Código no válido",
				],
				folio: [(v) => !!v || "Folio requerido"],
				email: [
					(v) => !!v || "E-mail requerido",
					(v) => /.+@.+\..+/.test(v) || "Email no válido",
				],
				pass: [(v) => !!v || "Contraseña requerida"],
				firstname: [(v) => !!v || "Nombre requerido"],
				firstsurname: [(v) => !!v || "Apellido requerido"],
				nacionalidad: [(v) => !!v || "Nacionalidad requerida"],
				buy: [(v) => !!v || "Número de compra requerido"],
				date: [(v) => !!v || "Fecha de compra requerida"],
				genero: [(v) => !!v || "Selección del sexo, requerida"],
				fono: [(v) => !!v || "Ingresar Número de Celular, requerida"],
			},
			createRouteDialog: false,
			date: new Date().toISOString().substr(0, 10),
			menu: false,
			modal: false,
		};
	},
};
</script>
<style lang="scss" scoped>
.pronto {
	position: absolute;
	top: 0;
	background-color: red;
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 0.7rem;
	right: 0;
}

.activo {
	position: absolute;
	top: 0;
	background-color: green;
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 0.7rem;
	right: 0;
}
</style>
