var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"niu-width-track",attrs:{"light":"","elevation":"0"}},[_c('v-row',[_c('v-col',[_c('div',[_c('div',{staticClass:"niu-label",class:_vm.iconTrackClass(_vm.infoTrack.trackAreaconocimientoCode)},[_c('v-icon',{staticStyle:{"display":"block"},attrs:{"color":"white","size":_vm.sizeLogoTrack * 0.6}},[_vm._v(" mdi-bookmark-multiple ")]),_c('br'),_c('small',{staticStyle:{"display":"block","color":"white"}},[_vm._v("Track")])],1),_c('div',{staticClass:"text-track-title",attrs:{"id":_vm.infoTrack.trackId}},[_c('label',[_vm._v(_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")]),_c('v-avatar',{attrs:{"size":_vm.sizeLogoTrack}},[_c('v-img',{attrs:{"size":_vm.sizeLogoTrack,"src":_vm.infoTrack.isPremium === 0
										? require('../../assets/weblogo_n.png')
										: require('../../assets/logo_premium_150x150.png')}})],1),(_vm.infoTrack.trackMobileFriendly === 1)?_c('v-avatar',{attrs:{"size":_vm.sizeLogoTrack,"color":"primary"}},[_c('v-img',{attrs:{"size":_vm.sizeLogoTrack,"src":require('../../assets/iconos/moviles.png')}})],1):_vm._e(),(_vm.infoTrack.isPersonalizadoNiufleex == 1)?_c('span',[_vm._v(" Personalizado ")]):_vm._e(),_c('div',{staticClass:"niu-iconos"},[_c('label',[_c('v-icon',{staticStyle:{"color":"#717171"}},[_vm._v("mdi-ballot-outline")]),_vm._v(" Módulos: "+_vm._s(_vm.modulos.length)+" ")],1),(_vm.trackSesiones != 0)?_c('label',[_c('v-icon',{staticStyle:{"color":"#717171"}},[_vm._v("mdi-file-document")]),_vm._v(" Sesiones: "+_vm._s(_vm.trackSesiones || "00")+" ")],1):_vm._e(),(_vm.trackDuracion != 0)?_c('label',[_c('v-icon',{staticStyle:{"color":"#717171"}},[_vm._v("mdi-clock-time-three-outline")]),_vm._v(" Duración: "+_vm._s(_vm.trackDuracion || "000")+" min")],1):_vm._e()]),_c('div',{staticClass:"niu-iconos"},[_c('label',[_c('v-icon',{staticStyle:{"color":"#717171"}},[_vm._v("mdi-tag")]),_vm._v(" Área de Conocimiento: "+_vm._s(_vm.KnowledgeAreas2.find( function (ele) { return ele.name == _vm.infoTrack.trackAreaconocimientoCode; } ).descripcion)+" ")],1)]),(_vm.infoTrack.trackProgreso == 0 && _vm.trackAddLearning == 1)?[(
									_vm.modulos.length >= 4 &&
									_vm.infoTrack.modulos.length == 0 &&
									_vm.$router.currentRoute.name != 'navega'
								)?_c('v-chip',{staticStyle:{"background-color":"#ff8b02 !important","color":"white !important"},on:{"click":function($event){return _vm.openDialog(_vm.infoTrack.trackProgreso)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")]),_c('label',{staticClass:"d-flex d-sm-none"},[_vm._v(" Agregar ")]),_c('label',{staticClass:"d-none d-sm-flex"},[_vm._v(" Agregar el Track a Mis rutas de aprendizaje ")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-transit-connection-horizontal ")])],1):_vm._e()]:(
								_vm.infoTrack.trackProgreso == 1 &&
								_vm.$router.currentRoute.name != 'navega'
							)?[_c('v-chip',{staticStyle:{"background-color":"#ff8b02 !important"}},[_c('label',[_vm._v(" Ruta agregada ")])])]:_vm._e()],2)])])],1),_c('v-slide-group',{attrs:{"active-class":"success","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.modulos),function(modulo,index){return _c('v-slide-item',{key:("modulo_" + (_vm.$router.currentRoute.name) + "_" + (_vm.infoTrack.trackCode.substr(
					0,
					5
				)) + "_" + (modulo.agrupaModuloId) + "_slide" + index)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var hover = ref.hover;
return [_c('v-card',{staticClass:"niu-card-main niu-background niu-text",class:{
							'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
							'niu-light': _vm.infoTrack.isPremium === 0 ? true : false,
						},attrs:{"elevation":hover ? 15 : 1}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{staticStyle:{"position":"relative !important"},attrs:{"aspect-ratio":17 / 9,"src":_vm.loadImage(modulo.moduloImg, modulo.comming_soon),"lazy-src":require("../../assets/client-loader.gif")}},[_c('div',{staticStyle:{"position":"absolute","top":"45%","right":"45%","z-index":"1"}},[_c('v-btn',{staticClass:"niu-play-modulo",staticStyle:{"text-shadow":"1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc,"},attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(modulo)}}},[_c('v-icon',{attrs:{"size":"70px"}},[_vm._v(" mdi-play-circle ")])],1)],1),_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px"}},[_c('v-chip',{staticClass:"ma-2",class:_vm.colorFondoModuloClass(modulo)},[_vm._v(" Módulo "+_vm._s(modulo.moduloNumber)+" ")])],1)])],1)],1),_c('v-row',{staticClass:"pa-2 ma-0",attrs:{"align":"end"}},[_c('v-col',{staticClass:"ma-0 pa-0 niu-text-carrusel"},[_c('div',{staticStyle:{"position":"absolute","top":"-35px","right":"20px"}},[_c('v-avatar',{class:_vm.colorFondoModuloClass(modulo),attrs:{"size":"42"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(modulo.moduloNumber))])])],1),(!modulo.moduloHtml)?_c('div',{staticClass:"niu-carousel-texto-modulo"},[_vm._v(" "+_vm._s(modulo.moduloTitle)+" ")]):_c('span',{staticClass:"niu-carousel-texto-modulo",domProps:{"innerHTML":_vm._s(modulo.moduloTitle)}}),_c('p',{staticClass:"d-none d-sm-flex",staticStyle:{"height":"50px","padding":"15px 0 0 0"}},[_c('v-icon',{staticClass:"niu-text size-7-rem",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
											{'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
											'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v(" mdi-account ")]),_c('label',{staticClass:"niu-text"},[_c('strong',[_vm._v("Especialista: ")]),_vm._v(" "+_vm._s(modulo.moduloEspecialista))])],1)])],1),_c('v-sheet',{staticClass:"transparent",staticStyle:{"position":"absolute","bottom":"15px","width":"90%","left":"5%"},attrs:{"align":"center","justify":"end","light":""}},[_c('small',{staticClass:"niu-text-bold niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
									{'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
									'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v("Sesiones: "+_vm._s(modulo.moduloNroSesiones))]),_c('small',{staticClass:"niu-text-bold ml-4 niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
									{'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
									'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v("Tiempo: "+_vm._s(modulo.moduloDuracion)+" minutos")])]),_c('div',{staticClass:"niu-barra",class:_vm.barraInferiorModuloCarrusel(modulo, hover)})],1)]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }