import Vue from 'vue';
import Vuex from 'vuex';
import carruseles from './modules/carruseles';
import Areas from '../services/listaAreaConocimiento.js';
import Rules from '../services/routeRules.js';
import Progress from '../services/progressTrack.js';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        premium: false,
        ruta_pathname: null,
        load_team: {
            team_title: '',
            team_code: '',
            team_guid: ''
        },
        modalShowConfirmTeam: false,
        modalContentConfirmTeam: null,
        modalShowConfirm: false,
        modalContentConfirm: null,
        modalShowQuiz: false,
        modalContentQuiz: null,
        modalVerMasInformacion: false,
        contentVerMasInformacion: {},
        loading: false,
        load_payperview: -1,
        membresia: null,
        infoTrack: {
            trackTitle: '',
            trackCode: '',
            trackId: null,
            trackAreaconocimientoCode: '',
            trackProgreso: null,
            modulos: [],
        },
        addRutaTrack: {
            modulos: [],
            createRouteDialog: false,
            trackTitle: '',
        },
        vencimientoPaso: false,
        //* universo */
        dialogRuta: false,
        progressTracks: Progress,
        KnowledgeAreas2: Areas,
        universe: {
            bgColor: 'transparent',
        },
        selectedDegree: '',
        degreeMax: {
            '': 0,
            track: 5,
            mastertrack: 3,
            master: 4,
            especialidad: 5,
        },
        degreesLoad: {
            track: [],
            mastertrack: [],
            master: [],
            especialidad: [],
        },
        degree: ['track', 'mastertrack', 'master', 'especialidad'],
        degreesCode: {
            track: 'Track',
            mastertrack: 'Master Track',
            master: 'Master',
            especialidad: 'Especialidad',
        },
        degreeSelected: [],
        degreeReOrdenByAreas: {
            track: [],
            mastertrack: [],
            master: [],
            especialidad: [],
        },
        degrees: [{
                code: 'track',
                title: 'Track',
            },
            {
                code: 'mastertrack',
                title: 'Master Track',
            },
            {
                code: 'master',
                title: 'Master',
            },
            {
                code: 'especialidad',
                title: 'Especialidad',
            },
        ],
        createRouteDialog: false,
        knowledgeAreas: [],

        modulosTrack: [],
        learningPath: {
            degreeId: null,
            degreeTitle: null,
            userid: null,
            token: null,
            data: [],
            maxLengthReached: false,
        },
        selectedTrack: null,
        //* universo */
        showVideo: false,
        alert: {
            message: '',
            type: '',
            snackbar: false,
            timeout: 5000,
            equis: true,
            top: true,
            moreinfo: false,
            adicional: false,
            msmAdicional: '',
        },
        users: {
            id: '',
            firstname: '',
            middlename: '',
            firstsurname: '',
            secondsurname: '',
            username: '',
            password: '',
            token: '',
            email: '',
            folio: '',
        },
        dialog: false,
        pause: false,
        mute: true,
        numberVideo: 0,
        moduloSeleccionado: {
            id: 0,
            titulo: '',
            bajada: '',
            especialistas: [{
                id: 0,
                nombre: '',
                apellido: '0',
            }, ],
            anio: 0,
            book: '',
            areaconocimiento: [{
                porcentual: '0',
                nombre: '',
                id: 0,
                value: 'nada',
            }, ],
            img: '',
            videos: [],
        },
        theme: {
            primary: '#541983', // '#4d1552',
            secondary: '#ff8b02',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
            background: '#f5f5f5',
            footertext: '#b26fe7',
            backcontinuar: '#6a289f',
            backFooterUno: '#4b0b7f',
            backFooterDos: '#541983',
            backCardModulos: '#5a069e',
            backPlayer: '#4b0b7f',
            backMenu: '#541983',
            title: '#3e026e',
            btnFicha: '#8208b1'
        }
    },

    mutations: {
        SET_PREMIUM(state, premium) {
            state.premium = premium;
        },
        SET_VUETIFY(state, theme) {
            state.theme = {
                ...state.theme,
                ...theme
            };
        },
        SET_RUTA(state, ruta) {
            state.ruta_pathname = ruta
        },
        SET_LOADTEAM(state, team) {
            state.load_team = team
        },
        SET_MODALSHOWCONFIRMTEAM(state, modalShowConfirmTeam) {
            state.modalShowConfirmTeam = modalShowConfirmTeam;
        },
        SET_MODALCONTENTCONFIRMTEAM(state, modalContentConfirmTeam) {
            state.modalContentConfirmTeam = modalContentConfirmTeam;
        },
        SET_MODALSHOWCONFIRM(state, modalShowConfirm) {
            state.modalShowConfirm = modalShowConfirm;
        },
        SET_MODALCONTENTCONFIRM(state, modalContentConfirm) {
            state.modalContentConfirm = modalContentConfirm;
        },
        SET_MODALSHOWQUIZ(state, modalShowQuiz) {
            state.modalShowQuiz = modalShowQuiz;
        },
        SET_MODALCONTENTQUIZ(state, modalContentQuiz) {
            state.modalContentQuiz = modalContentQuiz;
        },
        SET_MODALVERMASINFORMACION(state, modalVerMasInformacion) {
            state.modalVerMasInformacion = modalVerMasInformacion;
        },
        SET_CONTENTVERMASINFORMACION(state, contentVerMasInformacion) {
            state.contentVerMasInformacion = contentVerMasInformacion;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_LOAD_PAYPERVIEW(state, load_payperview) {
            state.load_payperview = load_payperview;
        },
        SET_MEMBRESIA(state, membresia) {
            state.membresia = membresia;
        },
        SET_INFOTRACK(state, infoTrack) {
            state.infoTrack = infoTrack;
        },
        SET_ADDRUTATRACK(
            state, {
                modulos = [],
                createRouteDialog = false,
                trackTitle = ''
            },
        ) {
            state.addRutaTrack = {
                modulos,
                createRouteDialog,
                trackTitle
            };
        },
        SET_VENCIMIENTOPASO(state, vencimientoPaso) {
            state.vencimientoPaso = vencimientoPaso;
        },
        showVideo(state, showVideo) {
            state.showVideo = showVideo;
        },
        alert(state, alert) {
            let equis = !!alert.equis;
            let top = alert.top == undefined ? true : alert.top;
            state.alert = {
                ...alert,
                equis,
                top
            };
        },
        SET_ALERT(state, alert) {
            let equis = !!alert.equis;
            let top = alert.top == undefined ? true : alert.top;
            state.alert = {
                ...alert,
                equis,
                top
            };
        },
        users(state, users) {
            state.users = users;
        },
        dialogTrue(state, {
            dialog
        }) {
            state.dialog = dialog;
        },
        dialog(state, {
            dialog,
            modulo,
            numberVideo
        }) {
            state.dialog = dialog;
            state.numberVideo = numberVideo;
            if (dialog) state.moduloSeleccionado = modulo;
        },
        mute(state, payload) {
            state.videoMain.mute = payload.mute;
        },
        pause(state, payload) {
            state.videoMain.mute = payload.mute;
        },
        moduloSeleccionado(state) {
            return state.moduloSeleccionado;
        },
        SET_CURRENT_TRACK(state, payload) {
            state.selectedTrack = payload;
        },
        ADD_TO_PATH(state, payload) {
            state.learningPath.data.push(payload);
        },
        ADD_TITLE(state, degreeTitle) {
            state.learningPath.degreeTitle = degreeTitle;
        },
        REMOVE_FROM_PATH(state, payload) {
            state.learningPath.data.splice(payload, 1);
        },
        CLEAR_learningPathData(state) {
            state.learningPath.data = [];
        },
        SET_DATA_learningPathData(state, data) {
            state.learningPath.data = data;
        },
        SET_learningPathData(
            state, {
                degreeId = null,
                degreeTitle = null,
                userid = null,
                token = null,
                data = [],
                maxLengthReached = null,
            },
        ) {
            state.learningPath = {
                degreeId,
                degreeTitle,
                userid,
                token,
                data,
                maxLengthReached,
            };
        },
        SET_TRACK(state, payload) {
            state.degreesLoad.track = payload;
        },
        SET_MASTERTRACK(state, payload) {
            state.degreesLoad.mastertrack = payload;
        },
        SET_MASTER(state, payload) {
            state.degreesLoad.master = payload;
        },
        SET_ESPECIALIDAD(state, payload) {
            state.degreesLoad.especialidad = payload;
        },
        SET_CART_MESSAGE(state, payload) {
            state.learningPath = payload;
        },
        UNIVERSE_BACKGROUND(state, payload) {
            state.universe.bgColor = payload;
        },
        FLUSH_ROUTE(state) {
            Object.assign(state.learningPath, {
                data: [],
                message: null
            });
        },
        SET_AREAS(state, KnowledgeAreas) {
            state.KnowledgeAreas = KnowledgeAreas;
        },
        SET_modulosTrack(state, {
            areaId,
            modulos
        }) {
            state.modulosTrack[areaId] = modulos;
        },
        SET_createRouteDialog(state, createRouteDialog) {
            state.createRouteDialog = createRouteDialog;
        },
        SET_selectedDegree(state, selectedDegree) {
            state.selectedDegree = selectedDegree;
        },
        SET_PROGRESSTRACKS(state, progressTracks) {
            state.progressTracks = progressTracks;
        },
        SET_degreeReOrdenByAreas(state, {
            areaId,
            modulos,
            degree
        }) {
            state.degreeReOrdenByAreas[degree][areaId] = modulos;
        },
    },
    actions: {
        dialog({
            commit
        }, {
            dialog,
            modulo,
            numberVideo
        }) {
            commit('dialog', {
                dialog,
                modulo,
                numberVideo
            });
        },
        ADD_TO_PATH(context, payload) {
            context.commit('ADD_TO_PATH', payload);
        },
        SET_TRACK(context, payload) {
            context.commit('SET_TRACK', payload);
        },
    },
    modules: {
        carruseles,
    },
    getters: {
        getKnowledgeAreas(state) {
            return state.knowledgeAreas;
        },
        KnowledgeAreas(state) {
            return state.knowledgeAreas;
        },
        Tracks(state) {
            return state.track;
        },
        LearningPath(state) {
            return state.learningPath;
        },
        Color(state) {
            return state.universe.bgColor;
        },
        SelectedTrack(state) {
            return state.selectedTrack;
        },
        LearningPathMax(state) {
            if (state.selectedTrack === 0) {
                return Rules.track.maxLength;
            }

            return 99;
        },
    },
});