<template>
  <v-dialog :max-width="400" v-model="modalShowConfirm" class="elevation-2">
    <v-card
      :style="`background-color:${$vuetify.theme.defaults.light.primary}`"
    >
      <v-card-text>
        <div>
          <v-btn
            :style="
              `position:absolute;z-index: 1; top:0;right:0;background-color:${$vuetify.theme.defaults.light.secondary} ;font-size:12px;`
            "
            icon
            dark
            @click="close"
            circle
            size="10"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3 class="pa-5">
            ¡Correo electrónico confirmado! Te invito a que sigas siendo parte
            de la Re-Evolución Educativa con Niufleex.
          </h3>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      heightFrame: 600,
      videoplayer: null,
      rating: 0,
      form: {},
      enviado: false,
      alert: false
    }
  },
  mounted () {},
  computed: {
    ...mapState(['modalShowConfirm', 'modalContentConfirm'])
  },
  methods: {
    ...mapMutations(['SET_MODALSHOWCONFIRM', 'SET_MODALCONTENTCONFIRM']),
    close () {
      this.SET_MODALSHOWCONFIRM(false)
    //   this.$route.query={}
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
