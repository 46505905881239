const nac = [
    'Perú',
    'Chile',
    'México',
    'Bolivia',
    'Argentina',
    'Costa Rica',
    'Ecuador',
    'Colombia',

    'Afganistán',
    'Albania',
    'Alemania',
    'Andorra',
    'Angola',
    'Anguila',
    'Antártida',
    'Antigua y Barbuda',
    'Arabia Saudita',
    'Argelia',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaiyán',
    'Bahamas',
    'Bangladés',
    'Barbados',
    'Baréin',
    'Bélgica',
    'Belice',
    'Benín',
    'Bermudas',
    'Bielorrusia',
    'Myanmar',
    'Bosnia y Herzegovina',
    'Botsuana',
    'Brasil',
    'Brunéi Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Bután',
    'Cabo Verde',
    'Camboya',
    'Camerún',
    'Canadá',
    'Catar',
    'Bonaire',
    'Chad',
    'China',
    'Chipre',
    'Comoras',
    'Corea',
    'Costa de Marfil',
    'Croacia',
    'Cuba',
    'Curaçao',
    'Dinamarca',
    'Dominica',
    'Egipto',
    'El Salvador',
    'Emiratos Árabes Unidos',
    'Eritrea',
    'Eslovaquia',
    'Eslovenia',
    'España',
    'Estados Unidos',
    'Estonia',
    'Etiopía',
    'Filipinas',
    'Finlandia',
    'Fiyi',
    'Francia',
    'Gabón',
    'Gambia',
    'Georgia',
    'Ghana',
    'Gibraltar',
    'Granada',
    'Grecia',
    'Groenlandia',
    'Guadalupe',
    'Guam',
    'Guatemala',
    'Guayana Francesa',
    'Guernsey',
    'Guinea',
    'Guinea-Bisáu',
    'Guinea Ecuatorial',
    'Guyana',
    'Haití',
    'Honduras',
    'Hong Kong',
    'Hungría',
    'India',
    'Indonesia',
    'Irak',
    'Irán',
    'Irlanda',
    'Isla Bouvet',
    'Isla de Man',
    'Isla de Navidad',
    'Isla Norfolk',
    'Islandia',
    'Islas Caimán',
    'Islas Cocos',
    'Islas Cook',
    'Islas Feroe',
    'Georgia del sur y las islas sandwich del sur',
    'Isla Heard e Islas McDonald',
    'Islas Malvinas',
    'Islas Marianas del Norte',
    'Islas Marshall',
    'Pitcairn',
    'Islas Salomón',
    'Islas Turcas y Caicos',
    'Islas de Ultramar Menores de Estados Unidos',
    'Islas Vírgenes',
    'Israel',
    'Italia',
    'Jamaica',
    'Japón',
    'Jersey',
    'Jordania',
    'Kazajistán',
    'Kenia',
    'Kirguistán',
    'Kiribati',
    'Kuwait',
    'Lao',
    'Lesoto',
    'Letonia',
    'Líbano',
    'Liberia',
    'Libia',
    'Liechtenstein',
    'Lituania',
    'Luxemburgo',
    'Macao',
    'Madagascar',
    'Malasia',
    'Malaui',
    'Maldivas',
    'Malí',
    'Malta',
    'Marruecos',
    'Martinica',
    'Mauricio',
    'Mauritania',
    'Mayotte',
    'Micronesia',
    'Moldavia',
    'Mónaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Mozambique',
    'Namibia',
    'Nauru',
    'Nepal',
    'Nicaragua',
    'Níger',
    'Nigeria',
    'Niue',
    'Noruega',
    'Nueva Caledonia',
    'Nueva Zelanda',
    'Omán',
    'Países Bajos',
    'Pakistán',
    'Palaos',
    'Palestina, Estado de',
    'Panamá',
    'Papúa Nueva Guinea',
    'Paraguay',
    'Polinesia Francesa',
    'Polonia',
    'Portugal',
    'Puerto Rico',
    'Reino Unido',
    'República Centroafricana',
    'República Checa',
    'Macedonia',
    'Congo',
    'República Dominicana',
    'Reunión',
    'Ruanda',
    'Rumania',
    'Rusia',
    'Sahara Occidental',
    'Samoa',
    'Samoa Americana',
    'San Bartolomé',
    'San Cristóbal y Nieves',
    'San Marino',
    'San Martín',
    'San Pedro y Miquelón',
    'San Vicente y las Granadinas',
    'Santa Helena',
    'Santa Lucía',
    'Santo Tomé y Príncipe',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra leona',
    'Singapur',
    'Sint Maarten',
    'Siria',
    'Somalia',
    'Sri Lanka',
    'Suazilandia',
    'Sudáfrica',
    'Sudán',
    'Sudán del Sur',
    'Suecia',
    'Suiza',
    'Surinam',
    'Svalbard y Jan Mayen',
    'Tailandia',
    'Taiwán',
    'Tanzania',
    'Tayikistán',
    'Territorio Británico del Océano Índico',
    'Territorios Australes Franceses',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad y Tobago',
    'Túnez',
    'Turkmenistán',
    'Turquía',
    'Tuvalu',
    'Ucrania',
    'Uganda',
    'Uruguay',
    'Uzbekistán',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Wallis y Futuna',
    'Yemen',
    'Yibuti',
    'Zambia',
    'Zimbabue'
]
export default nac