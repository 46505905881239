const findLocalIp = (logInfo = true) =>
  new Promise((resolve, reject) => {
    window.RTCPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection

    if (typeof window.RTCPeerConnection == 'undefined')
      return reject('WebRTC not supported by browser')

    let pc = new RTCPeerConnection()
    let ips = []

    pc.createDataChannel('')
    pc.createOffer()
      .then(offer => pc.setLocalDescription(offer))
      .catch(err => reject(err))
    pc.onicecandidate = event => {
      if (!event || !event.candidate) {
        // All ICE candidates have been sent.
        if (ips.length == 0)
          return reject('WebRTC disabled or restricted by browser')

        return resolve(ips)
      }

      let parts = event.candidate.candidate.split(' ')
      //eslint-disable-next-line
      let [
          //eslint-disable-next-line
        base,
        //eslint-disable-next-line
        componentId,
        //eslint-disable-next-line
        protocol,
        //eslint-disable-next-line
        priority,
        ip,
        //eslint-disable-next-line
        port,
        ,
        //eslint-disable-next-line
        type,
        //eslint-disable-next-line
        ...attr
      ] = parts
      //eslint-disable-next-line
      let component = ['rtp', 'rtpc']

      if (!ips.some(e => e == ip)) ips.push(ip)

      if (!logInfo) return
    }
  })
export default findLocalIp
