<!-- eslint-disable vue/valid-v-slot -->
<template>
	<v-container>
		<v-row class="mt-10">
			<v-col>
				<v-card>
					<v-card-title>
						Órdenes
						<v-btn :loading="btnRefreshOrdenes" @click="refreshOrdenes">
							Actualizar Órdenes
						</v-btn>
						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							hide-details
						></v-text-field>
					</v-card-title>
					<v-data-table
						:headers="headers"
						:items="ordenes"
						:search="search"
						sort-by="calories"
						class="elevation-1"
					>
						<template v-slot:top>
							<v-toolbar flat>
								<v-toolbar-title>Actualizar Ordenes</v-toolbar-title>
								<v-divider class="mx-4" inset vertical></v-divider>
								<v-spacer></v-spacer>

								<!-- <v-dialog v-model="dialogDelete" max-width="500px">
									<v-card>
										<v-card-title class="text-h5"
											>Are you sure you want to delete this item?</v-card-title
										>
										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn color="blue darken-1" text @click="closeDelete"
												>Cancel</v-btn
											>
											<v-btn
												color="blue darken-1"
												text
												@click="deleteItemConfirm"
												>OK</v-btn
											>
											<v-spacer></v-spacer>
										</v-card-actions>
									</v-card>
								</v-dialog> -->
							</v-toolbar>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<!-- <v-icon small class="mr-2" @click="editItem(item)">
								mdi-pencil
							</v-icon> -->
							<v-icon small class="mr-2" @click="linkItem(item)">
								mdi-link
							</v-icon>
							<!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
						</template>
						<!-- <template v-slot:no-data>
							<v-btn color="primary" @click="initialize"> Reset </v-btn>
						</template> -->
					</v-data-table>
				</v-card>
				<v-dialog v-model="dialog" max-width="600px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<label for="editedItem.orderid">
											Orden: {{ editedItem.orderid }}
										</label>
										<label for="editedItem.orderdate">
											Fecha Compra:
											{{ new Date(editedItem.orderdate) | dateDMY }}
										</label>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<label for="editedItem.quantity">
											{{ editedItem.quantity }}
											{{ editedItem.quantity > 1 ? "Meses" : "Mes" }}
										</label>
										<label for="editedItem.itemcode">
											{{ editedItem.itemcode }}
										</label>
										-
										<label for="editedItem.description">
											{{ editedItem.description }}
										</label>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<label for="editedItem.description">
											{{ editedItem.status }}
										</label>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<template
											v-if="editedItem.status == 'Libre' && editedItem.link"
										>
											<v-btn :to="`/active?code=${editedItem.link}`">
												Link
											</v-btn>
											<v-btn
												@click="copyText(`/active?code=${editedItem.link}`)"
											>
												Copiar Link
											</v-btn>
											<v-btn
												@click="whatsApp(`/active?code=${editedItem.link}`)"
											>
												whatsApp
											</v-btn>
										</template>
										<v-btn
											v-else-if="editedItem.status == 'Libre'"
											:loading="loadingCrear"
											@click="crearLink(editedItem)"
										>
											Crear Link
										</v-btn>
									</v-col>
									<v-col v-if="editedItem.status == 'Libre'">
										<v-btn
											:loading="btnActive"
											@click="activarOrden(editedItem)"
										>
											Activarme
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
/* eslint no-console: "error" */
export default {
	name: "OrdenesEit",
	data: () => ({
		search: "",
		btnActive: false,
		btnRefreshOrdenes: false,
		loadingCrear: false,
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: "Orden",
				align: "start",
				sortable: false,
				value: "orderid",
			},
			{ text: "Fecha Orden", value: "orderdate" },
			{ text: "Code", value: "itemcode" },
			{ text: "Descripción", value: "description" },
			{ text: "Cantidad", value: "quantity" },
			{ text: "Estado", value: "status" },
			{ text: "Actions", value: "actions", sortable: false },
		],
		ordenes: [],
		editedIndex: -1,
		editedItem: {
			orderid: "",
			orderdate: "",
			itemcode: 0,
			description: 0,
			quantity: 0,
			status: "",
		},
		defaultItem: {
			orderid: "",
			orderdate: "",
			itemcode: 0,
			description: 0,
			quantity: 0,
			status: "",
		},
	}),
	filters: {
		dateDMY: function (value) {
			if (!value) return "";
			value =
				value.getFullYear() +
				"-" +
				(value.getMonth() + 1) +
				"-" +
				value.getDate();
			return value;
		},
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? "Add" : "Detalles link";
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created() {
		this.initialize();
	},

	methods: {
		async refreshOrdenes() {
			try {
				this.btnRefreshOrdenes = true;
				(await this.$http.post("/users/refreshOrdenes")).data;
				this.initialize();
				// eslint-disable-next-line no-empty
			} catch (error) {
			} finally {
				this.btnRefreshOrdenes = false;
			}
		},
		async activarOrden(item) {
			alert("...activando");
			try {
				this.btnActive = true;
				let result = await this.$http.post("/users/ActivarPlan", {
					orderid: item.orderid,
					itemcode: item.itemcode,
				});
				// eslint-disable-next-line no-console
				console.log(result);
				if (result) {
					this.$commit("SET_ALERT", {
						message: "Plan activado, actualiza",
						type: "sucess",
						snackbar: true,
					});
					this.initialize();
					
				} else {
					this.$commit("SET_ALERT", {
						message: "Inténtalo nuevamente",
						type: "error",
						snackbar: true,
					});
				}
				// eslint-disable-next-line no-console
				console.log(result);
			} catch (error) {
				if (error.response) {
					if (error.response) {
						alert("Cuenta activa");
						// eslint-disable-next-line no-console
						console.log(error.response)
					} else {
						alert("Inténtelo nuevamente");
					}
				}
			} finally {
				this.btnActive = false;
				window.location.reload()
			}
		},
		copyText(text_to_copy) {
			if (process.env.NODE_ENV == "development") {
				text_to_copy = "http://localhost:8081" + text_to_copy;
			} else {
				text_to_copy = "https://app.beinfinitus.com" + text_to_copy;
			}
			if (!navigator.clipboard) {
				// use old commandExec() way
			} else {
				navigator.clipboard
					.writeText(text_to_copy)
					.then(function () {
						alert("Formulario inteligente copiado"); // success
					})
					.catch(function () {
						alert("err"); // error
					});
			}
		},
		whatsApp(text_to_copy) {
			if (process.env.NODE_ENV == "development") {
				text_to_copy = "http://localhost:8081" + text_to_copy;
			} else {
				text_to_copy = "https://app.beinfinitus.com" + text_to_copy;
			}
			window.open(
				"https://api.whatsapp.com/send?text=" + text_to_copy,
				"_blank"
			);
		},
		async crearLink(item) {
			this.loadingCrear = true;
			try {
				let result = (
					await this.$http.post("/users/crearLink", {
						orderid: item.orderid,
						itemcode: item.itemcode,
					})
				).data;
				// eslint-disable-next-line no-console
				console.log(result);
				if (result) {
					item.link = result.code;
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			} finally {
				this.loadingCrear = false;
				this.initialize();
			}
		},
		linkItem(item) {
			this.editedIndex = this.ordenes.indexOf(item);
			this.editedItem = Object.assign({}, item);
			// eslint-disable-next-line no-console
			console.log(item);
			this.dialog = true;
		},
		async initialize() {
			this.ordenes = [];
			try {
				let result = (await this.$http.post("/users/ordenes")).data;
				this.ordenes = result;
				// eslint-disable-next-line no-console
				console.log(result)
				this.ordenes.map((ele) => {
					ele.status =
						ele.orden_activada_tipo == "eit"
							? "Usada"
							: ele.orden_activada_tipo == "cliente"
							? "Usada Link"
							: "Libre";
				});
				// eslint-disable-next-line no-empty
			} catch (error) {}
		},
		editItem(item) {
			this.editedIndex = this.ordenes.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.ordenes.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.ordenes.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				Object.assign(this.ordenes[this.editedIndex], this.editedItem);
			} else {
				this.ordenes.push(this.editedItem);
			}
			this.close();
		},
	},
};
</script>