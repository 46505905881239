<template>
  <v-layout row justify-center>
    <v-dialog
      persistent
      max-width="600"
      v-model="modalPreguntas"
      class="elevation-0 modal-preguntas"
      light
    >
      <!-- <v-btn style="position:absolute;z-index: 1;" icon light @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn> -->

      <v-card light>
        <v-card-text light>
          <v-row style="background-color: white;" dark>
            <v-col>
              <h3>
                1:- Para poder acompañarte de mejor manera en este proceso de
                aprendizaje, quisieramos saber ¿qué te motiva a completar este
                Track?
              </h3>
              <v-radio-group v-model="motivaciones">
                <v-radio value="laborales">
                  <template v-slot:label>
                    <div>
                      a) Motivaciones laborales/de emprendimiento
                    </div>
                  </template>
                </v-radio>
                <v-radio value="crecimiento">
                  <template v-slot:label>
                    <div>
                      b) De crecimiento personal
                    </div>
                  </template>
                </v-radio>
                <v-radio value="interpersonales">
                  <template v-slot:label>
                    <div>
                      c) Para favorecer tus relaciones interpersonales.
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <h3>
                2.- Si tuvieras que identificarte en un nivel de conocimiento
                con respecto a la Cosmetología Aplicada, ¿en qué nivel te
                ubicarías?
              </h3>
              <v-radio-group v-model="nivelconocimiento">
                <v-radio value="principiante">
                  <template v-slot:label>
                    <div>
                      a) Principante
                    </div>
                  </template>
                </v-radio>
                <v-radio value="intermedio">
                  <template v-slot:label>
                    <div>
                      b) Intermedio
                    </div>
                  </template>
                </v-radio>
                <v-radio value="experimentado">
                  <template v-slot:label>
                    <div>
                      c) Experimentado
                    </div>
                  </template>
                </v-radio>
                <v-radio value="avanzado">
                  <template v-slot:label>
                    <div>
                      d) Avanzado
                    </div>
                  </template>
                </v-radio>
                <v-radio value="experto">
                  <template v-slot:label>
                    <div>
                      e) Experto
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="btnEnviarPreguntas">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    modalPreguntas: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      radios: null,
      motivaciones: null,
      nivelconocimiento: null
    }
  },
  methods: {
    close () {
      this.$emit('closeModalPreguntas', false)
    },
    async btnEnviarPreguntas () {
      if (this.$route.params.track) {
        let response = (
          await this.$http.post('/certificacion/addPreguntaExploratoria', {
            trackname: this.$route.params.track,
            pregunta_motivaciones: this.motivaciones,
            pregunta_nivelconocimiento: this.nivelconocimiento
          })
        ).data

        // eslint-disable-next-line no-console
        console.log(response)
        this.$emit('closeModalPreguntas', !response.valid)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
.modal-preguntas > * {
  overflow: hidden;
}
</style>
