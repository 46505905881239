<template>
  <v-row
    justify="center"
    class="pa-0 ma-0 mt-md-15 mt-xl-15 pt-2 niu-bg-page"
    style="color:black;"
  >
    <!-- <NiuPreguntas
      :modalPreguntas="modalPreguntas"
      @closeModalPreguntas="closeModalPreguntas"
    ></NiuPreguntas> -->
    <v-col cols="12" class="ma-0" v-if="modulo && !errorAcceso">
      <v-row>
        <v-col>
          <div style="position:relative;">
            <v-chip :class="iconTrackClass(modulo.areaconocimientoCode)">
              <v-icon color="white">
                mdi-bookmark-multiple
              </v-icon>
              <small style="color: white;font-weight:bold;">Módulo</small>
            </v-chip>
            <label class="niu-title-modulo" v-text="modulo.moduloTitle">
            </label>
            <div class="niu-content-iconos">
              <div class="niu-iconos">
                <label>
                  <v-icon style="color: #717171;">mdi-bookmark-multiple</v-icon>
                  Sesiones: {{ videos.length || '00' }}
                </label>
                <label>
                  <v-icon style="color: #717171;">mdi-bookmark-multiple</v-icon>
                  Área de Conocimiento:
                  {{ modulo.areaconocimientoTitle }}
                </label>
              </div>
            </div>
          </div>
          <h2 v-if="modulo.trackMasterClass == 0">Sobre el módulo</h2>
          <h2 v-else>Sobre la Clase Maestra</h2>
          <p style="color:black">
            {{ modulo.moduloDescripcion }}
          </p>
        </v-col>
        <v-col cols="4" justify="center" align="center">
          <v-avatar size="200">
            <img
              v-if="
                modulo.especialistas.length > 0 &&
                  modulo.especialistas[0].especialistaImg
              "
              alt="Avatar"
              :src="imgEspecialista(modulo)"
            />
          </v-avatar>
          <br />
          <label style="color:black"
            ><strong>Especialista: </strong>
            {{
              modulo.especialistas.length > 0 &&
              modulo.especialistas[0].especialistaNickname
                ? modulo.especialistas[0].especialistaNickname
                : ''
            }}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider color="black"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <CarouselModulo
            :videos="videos"
            :isPremium="modulo.isPremium"
            @OpenDialogModuloPremium="OpenDialogModuloPremium"
          ></CarouselModulo>

          <NiuDialogModuloPremium
            :dialogModuloPremium="dialogModuloPremium"
            @CloseDialogModuloPremium="CloseDialogModuloPremium"
          ></NiuDialogModuloPremium>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      {{ mensajeError }}
    </v-col>
  </v-row>
</template>
<script>
import CarouselModulo from '../components/modulo/carrouselModulo.vue'
import NiuDialogModuloPremium from '../components/modulo/dialogPremium.vue'
import NiuPreguntas from '../components/modulo/preguntas.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    CarouselModulo,
    NiuDialogModuloPremium,
    NiuPreguntas
  },
  props: {},
  data: () => {
    return {
      videos: null,
      moduloSeleccionado: null,
      modulo: null,
      errorAcceso: false,
      mensajeError: '',
      dialogModuloPremium: false,
      modalPreguntas: false
    }
  },

  async created () {
    this.validarPreguntaExploratoria()
    if (
      (this.$router.currentRoute.name == 'modulo' ||
        this.$router.currentRoute.name == 'modulo_') &&
      this.$route.params.id
    ) {
      let response = await this.$http.get(
        `/Videos/ModuloCode/${this.$route.params.id}`
      )
      if (response && response.data) {
        this.videos = response.data
        if (this.videos.length > 0) {
          let response2 = await this.$http.get(
            `/Modulos/ModuloId/${this.videos[0].moduloId}`
          )

          this.modulo = response2.data
          // eslint-disable-next-line no-console
          console.group('modulo')
          // eslint-disable-next-line no-console
          console.log(this.modulo, this.videos)
          // eslint-disable-next-line no-console
          console.groupEnd('modulo')
        } else {
          this.errorAcceso = true
          this.mensajeError = 'Error de acceso'
        }
      } else {
        this.errorAcceso = true
        this.mensajeError = 'Error de acceso'
      }
    } else {
      this.errorAcceso = true
      this.mensajeError = 'Error de acceso'
    }
  },
  methods: {
    ...mapMutations('carruseles', ['SET_MODULO']),
    async validarPreguntaExploratoria () {
      if (this.$route.params.track) {
        let response = (
          await this.$http.post('/certificacion/getPreguntaExploratoria', {
            trackname: this.$route.params.track
          })
        ).data

        // eslint-disable-next-line no-console
        console.log(response)
        this.modalPreguntas = !response.valid
      }
    },
    closeModalPreguntas () {
      this.modalPreguntas = false
    },
    CloseDialogModuloPremium () {
      this.dialogModuloPremium = false
    },
    OpenDialogModuloPremium () {
      // eslint-disable-next-line no-console
      console.log('paso')
      this.dialogModuloPremium = true
    },
    imgEspecialista (modulo) {
      try {
        return require(`../assets/especialistas/${modulo.especialistas[0].especialistaImg}`)
      } catch (error) {
        return require(`../assets/especialistas/blanco.jpg`)
      }
    },
    iconTrackClass (areaconocimientoCode) {
      return {
        'niu-background-solid-emprendimientonegocio':
          areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-solid-liderazgo': areaconocimientoCode == 'liderazgo',
        'niu-background-solid-comunicacion':
          areaconocimientoCode == 'comunicacion',
        'niu-background-solid-desarrollofisico':
          areaconocimientoCode == 'desarrollofisico',
        'niu-background-solid-desarrollopersonal':
          areaconocimientoCode == 'desarrollopersonal',
        'niu-background-solid-conocimientotecnico':
          areaconocimientoCode == 'conocimientotecnico'
      }
    },
    dialog () {
      document.documentElement.classList.remove('niu-overflow-hidden')
      this.$store.commit('dialog', { dialog: false, modulo: {} })
      this.$router.push('/')
    },
    dialogProgreso () {
      document.documentElement.classList.remove('niu-overflow-hidden')
      this.$store.commit('dialog', { dialog: false, modulo: {} })
      this.$router.push('/progreso')
    }
  },
  computed: {
    ...mapState(['KnowledgeAreas2']),
    dialogModulo () {
      return this.$store.state.dialog
    },
    btnAreaConocimiento () {
      return {
        'niu-background-gradient-emprendimientonegocio':
          this.modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-gradient-liderazgo':
          this.modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-gradient-desarrollofisico':
          this.modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-gradient-desarrollopersonal':
          this.modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-gradient-conocimientotecnico':
          this.modulo.areaconocimientoCode == 'conocimientotecnico',
        'niu-background-gradient-comunicacion':
          this.modulo.areaconocimientoCode == 'comunicacion'
      }
    },
    classBarraVerticalModulo () {
      return {
        'niu-barra-vertical': true,
        'niu-background-gradient-emprendimientonegocio':
          this.modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-gradient-liderazgo':
          this.modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-gradient-desarrollofisico':
          this.modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-gradient-desarrollopersonal':
          this.modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-gradient-conocimientotecnico':
          this.modulo.areaconocimientoCode == 'conocimientotecnico',
        'niu-background-gradient-comunicacion':
          this.modulo.areaconocimientoCode == 'comunicacion'
      }
    },
    classBtnCloseModulo () {
      return {
        'mx-2': true,
        'niu-btn-close-modulo': true
        // 'niu-box-shadow-emprendimientonegocio':
        //   this.modulo.areaconocimientoCode == 'emprendimientonegocio',
        // 'niu-box-shadow-liderazgo':
        //   this.modulo.areaconocimientoCode == 'liderazgo',
        // 'niu-box-shadow-desarrollofisico':
        //   this.modulo.areaconocimientoCode == 'desarrollofisico',
        // 'niu-box-shadow-desarrollopersonal':
        //   this.modulo.areaconocimientoCode == 'desarrollopersonal',
        // 'niu-box-shadow-conocimientotecnico':
        //   this.modulo.areaconocimientoCode == 'conocimientotecnico',
        // 'niu-box-shadow-comunicacion':
        //   this.modulo.areaconocimientoCode == 'comunicacion'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$bg_page: #f5f5f5;
.niu-bg-page {
  background-color: $bg_page !important;
}
.niu-content-iconos {
  color: black;
  font-size: 35px;
  display: inline-block;
  width: 100%;
  position: relative;
  left: 55px;
  font-weight: bold;
}
.niu-iconos {
  font-size: 1.2rem;
  font-weight: normal;
  color: #717171;
}
.niu-label {
  display: inline-block;
  border-radius: 10px;
  line-height: 5px;
  padding: 10px;
}
.niu-title-modulo {
  color: black;
  font-size: 35px;
  display: inline-block;
  width: 80%;
  position: relative;
  left: 55px;
  font-weight: bold;
}

.niu-border-white-1 {
  border: 1px solid #fff;
}
.niu-color-primary-1-light {
  color: #a52882 !important;
}
.niu-color-dark {
  color: #000 !important;
}
.niu-font-bold {
  font-weight: bold !important;
}
.niu-bg-white {
  background-color: white !important;
}
.niu-font-size-20 {
  font-size: 20px;
}
.niu-listado-especialistas {
  font-size: 20px;
}
.niu-position-relative {
  position: relative;
}
.niu-no-border-radius-top {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.niu-no-border-radius-bottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.v-dialog {
  background-color: #000;
}
.v-dialog.v-dialog--active.v-dialog--fullscreen {
  background: transparent;
}
/* .niu-card-back-modulo */
.niu-modulo-video-texto {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 600px) {
  .niu-texto-modulo {
    /* width: 150px; */
    height: 800px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .niu-texto-modulo {
    /* width: 188px; */
    height: 500px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .niu-texto-modulo {
    /* width: 225px; */
    height: 500px;
  }
}
@media (min-width: 1264px) and (max-width: 1904px) {
  .niu-texto-modulo {
    /* width: 300px; */
    height: 500px;
  }
}
.niu-btn-close-modulo {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
/* .niu-shadow-box{
  box-shadow: 1px 1px 1px #000;
} */
.niu-box-shadow-emprendimientonegocio {
  box-shadow: -1px -1px 8px 1px #ffae04, 1px 0px 8px 1px #ffe800 !important;
}
.niu-box-shadow-liderazgo {
  box-shadow: -1px -1px 8px 1px #f64600, 1px 0px 8px 1px #f39d00 !important;
}
.niu-box-shadow-desarrollofisico {
  box-shadow: -1px -1px 8px 1px #770005, 1px 0px 8px 1px #f40000 !important;
}
.niu-box-shadow-desarrollopersonal {
  box-shadow: -1px -1px 8px 1px #2d6b00, 1px 0px 8px 1px #2ae82d !important;
}
.niu-box-shadow-conocimientotecnico {
  box-shadow: -1px -1px 8px 1px #11b29c, 1px 0px 8px 1px #3effe8 !important;
}
.niu-box-shadow-comunicacion {
  box-shadow: -1px -1px 8px 1px #354CF9, 1px 0px 8px 1px #0540f2 !important;
}
.niu-titulo-modulo {
  font-size: 38px;
}
.niu-bajada-modulo {
  font-size: 20px;
}
.niu-barra-vertical {
  position: absolute;
  height: 75%;
  width: 6px;
  top: 4%;
  background-color: transparent;
  left: 15px;
  border-radius: 20px;
}

$emprendimientonegocio: #FFB92E;
$liderazgo: #C10000;
$desarrollofisico: #FC3A72;
$desarrollopersonal: #8AC445;
$conocimientotecnico: #40CCB1;
$comunicacion: #354CF9;

.niu-text {
  font-size: 0.9rem;
}

.niu-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.niu-text-black {
  color: rgba(0, 0, 0, 1) !important;
}
.niu-text-emprendimientonegocio {
  color: $emprendimientonegocio !important;
}
.niu-text-liderazgo {
  color: $liderazgo !important;
}
.niu-text-desarrollofisico {
  color: $desarrollofisico !important;
}
.niu-text-desarrollopersonal {
  color: $desarrollopersonal !important;
}
.niu-text-conocimientotecnico {
  color: $conocimientotecnico !important;
}
.niu-text-comunicacion {
  color: $comunicacion !important;
}

.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
.v-application p {
  margin-bottom: 4px !important;
}
</style>
