<template>
  <v-dialog :max-width="400" v-model="modalShowConfirmTeam" class="elevation-2">
    <v-card
      :style="`background-color:${$vuetify.theme.defaults.light.primary}`"
    >
      <v-card-text>
        <div>
          <v-btn
            :style="
              `position:absolute;z-index: 1; top:0;right:0;background-color:${$vuetify.theme.defaults.light.secondary} ;font-size:12px;`
            "
            icon
            dark
            @click="close"
            circle
            size="10"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3 class="pa-5" >
            <p v-if="!change_message">
              Te estás uniendo a
            </p>
            <p v-else>
              ¡Felicidades! Ahora accederás al contenido de
            </p>
            <img
              width="100%"
              src="https://niufleex.com/wp-content/uploads/2021/10/48HRS-ChileDistintivo.png"
              alt="TEOMA CHILE"
            />
          </h3>
        </div>
      </v-card-text>
      <v-card-actions v-if="!change_message">
        <v-btn
          @click="activeTeam"
          :color="$vuetify.theme.defaults.light.secondary"
          dark
          :loading="loading"
          >Activar Equipo</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      heightFrame: 600,
      videoplayer: null,
      rating: 0,
      form: {},
      enviado: false,
      alert: false,
      loading: false,
      change_message: false
    }
  },
  mounted () {},
  computed: {
    ...mapState(['modalShowConfirmTeam', 'modalContentConfirmTeam'])
  },
  methods: {
    ...mapMutations([
      'SET_MODALSHOWCONFIRMTEAM',
      'SET_MODALCONTENTCONFIRMTEAM'
    ]),
    async activeTeam () {
      this.loading = true
      let respuesta = (
        await this.$http.post('/users/validate_team', {
          team_title: this.$route.query.validate_team
        })
      ).data

      if (respuesta) {
        // eslint-disable-next-line no-console
        console.log(respuesta)
        if (respuesta.message.includes('unido')) {
          let data = this.$storeNiu.getObjectValue('data')
          // eslint-disable-next-line no-console
          console.log(data)

          data.team_title = respuesta.status.team_title
          data.team_code = respuesta.status.team_code
          data.team_guid = respuesta.status.team_guid
          this.$storeNiu.set('data', data)
          this.$storeNiu.set('LOAD_TEAM', data.team_code)
          this.$storeNiu.set('LOAD_TEAMTITLE', data.team_title)
          this.$store.commit('SET_LOADTEAM', {
            team_title: data.team_title,
            team_code: data.team_code,
            team_guid: data.team_guid
          })
          this.change_message = true
        }
      }
      this.loading = false
    },
    close () {
      this.SET_MODALSHOWCONFIRMTEAM(false)
      //   this.$route.query={}
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
