<template>
  <div>
    <v-app-bar
      color="deep-purple"
      dark
      style="height: 96.09px; padding-top: 20px; padding-left: 10px"
      :style="{
        backgroundColor: `${themeStyle.backMenu} !important`
      }"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer2 = !drawer2"
        style="background-color: transparent !important"
      ></v-app-bar-nav-icon>
      <v-btn
        :href="logos.logo1.link"
        :target="logos.logo1.target"
        style="height: 0px"
      >
        <img
          class="mx-auto"
          :src="logos.logo1.src"
          style="height: 30px; margin-left: 0px"
        />
      </v-btn>
      <v-toolbar-items class="hidden-sm-and-down">
        <ul style="padding-left: 8px"></ul>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-btn light text @click="drawer = true" style="height: 0px; width: 0px">
        <v-avatar>
          <img
            :src="
              !dataUser
                ? 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'
                : (dataUser.genero = 'hombre'
                    ? 'https://niufleex.com/wp-content/uploads/2022/01/businessman.png'
                    : (dataUser.genero = 'mujer'
                        ? 'https://niufleex.com/wp-content/uploads/2022/01/businesswoman.png'
                        : 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'))
            "
            alt="niufleex"
          />
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      class="mnuxs"
      v-model="drawer2"
      absolute
      temporary
      style="background-color: #f77c0a"
      :height="alto"
    >
      <v-list nav dense>
        <v-toolbar-items>
          <ul style="padding-left: 8px">
            <li
              style="list-style: none"
              v-for="item in items"
              :key="item.boton"
            >
              <v-btn
                :href="item.link"
                :target="item.target"
                v-if="
                  item.membresia &&
                    item.membresia.length > 0 &&
                    item.subitems.length == 0 &&
                    item.membresia.includes(membresia)
                "
                text
                elevation="0"
                style="background-color: transparent !important; color: white"
              >
                {{ item.boton }}</v-btn
              >
              <v-menu
                :close-on-content-click="false"
                open-on-hover
                bottom
                offset-y
                v-else-if="
                  item.membresia &&
                    item.membresia.length > 0 &&
                    item.membresia.includes(membresia)
                "
                style="background-color: transparent !important"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    elevation="0"
                    style="
											background-color: rgb(247, 124, 10) !important;
											color: white;
										"
                    v-on="on"
                  >
                    {{ item.boton }}&nbsp;
                    <i style="font-size: 14px" class="fa fa-chevron-down"></i
                  ></v-btn>
                </template>

                <v-list style="background-color: rgb(247, 124, 10) !important">
                  <v-list-item
                    v-for="item2 in item.subitems"
                    :key="item2.boton"
                  >
                    <v-btn
                      text
                      elevation="0"
                      style="
												background-color: transparent !important;
												color: white;
											"
                      :href="item2.link"
                      :target="item2.target"
                    >
                      {{ item2.boton }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
          </ul>
        </v-toolbar-items>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      :height="alto"
    >
      <v-list class="py-0 listMenu" dense nav style="color: white !important">
        <v-list-item class="text-right">
          <label class="text-right" @click="drawer = false">
            <v-icon right> mdi-close-thick </v-icon>
          </label>

          <!-- <v-list-item-avatar right @click="primaryDrawer.model = false">
        </v-list-item-avatar> -->
          <!-- <v-list-item-content>
          <v-list-item-title>  </v-list-item-title>
        </v-list-item-content> -->
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon> mdi-account </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Hola </v-list-item-title>
            <v-list-item-subtitle>
              {{ firstname() }}
              {{ firstsurname() }}
              {{ secondsurname() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-account </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Tu usuario: </v-list-item-title>
            <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
            <v-list-item-subtitle>
              {{ username() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-email-edit </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Tu email registrado: </v-list-item-title>
            <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
            <v-list-item-subtitle>
              {{ email() }}
            </v-list-item-subtitle>
            <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-account </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Folio o Id Teoma: </v-list-item-title>
            <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
            <v-list-item-subtitle>
              {{ customerid() }}
            </v-list-item-subtitle>
            <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-key-change </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <router-link to="/change" replace>
                Cambiar Contraseña
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-account-off-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <button @click="cerrarSesion">Cerrar Sesión</button>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-account-edit </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <button
                :to="`/yo/${this.$storeNiu.getTextOrInt('user')}`"
                replace
              >
                Mis datos
              </button>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="color: white !important" v-if="team() != ''">
          <v-list-item-icon>
            <v-icon> mdi-email-edit </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
            <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
            <v-list-item-subtitle>
              {{ team() }}
            </v-list-item-subtitle>
            <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="color: white !important">
          <v-list-item-content>
            <v-list-item-title>
              <v-btn
                target="_blank"
                dark
                href="https://niufleex.com/terminos-y-condiciones/"
              >
                Terminos y Condiciones
              </v-btn>
            </v-list-item-title>
            <!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
            <v-list-item-subtitle>
              <v-btn
                dark
                target="_blank"
                href="https://niufleex.com/politicas-de-privacidad/"
              >
                Polícitas de Privacidad
              </v-btn>
            </v-list-item-subtitle>
            <!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider></v-divider>
				<v-list-item>
					<v-list-item-icon>
						<v-icon> mdi-backup-restore</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							<router-link
								:to="`/explora-free`"
								style="
									color: white !important;
									background-color: #de7409;
									border-radius: 8px;
									padding: 13px;
									text-decoration: none;
								"
							>
								Versión Anterior</router-link
							>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style>
.mnuxs .v-navigation-drawer__content {
  background-color: rgb(247, 124, 10);
  background: none !important;
}
.iface {
  font-size: 17px;
  padding-left: 12px;
  padding-top: 6px;
  border-width: 3px 3px 3px 3px;
  border-radius: 50%;
  color: white;
  border-color: white;
  width: 43px;
  height: 43px;
  border: solid;
  margin-right: 40px;
}
.iface:hover {
  color: #fb950c;
  border-color: #fb950c;
}
.btnmenu {
  background-color: transparent !important;
  color: white !important;
  font-family: 'Raleway', Sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.btnmenu:hover {
  color: #fb950c !important;
}
.btnmenu:hover::before {
  opacity: 0 !important;
}
</style>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'MenuXS',
  props: {
    themeStyle: {
      type: Object,
      default: () => {
        return {
          primary: '#541983', // '#4d1552',
          secondary: '#ff8b02',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          background: '#f5f5f5',
          footertext: '#b26fe7',
          backcontinuar: '#6a289f',
          backFooterUno: '#4b0b7f',
          backFooterDos: '#541983',
          backCardModulos: '#5a069e',
          backPlayer: '#4b0b7f',
          backMenu: '#541983'
        }
      }
    },
    premium: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: [
        {
          boton: 'Explora Free',
          link: '/explora/free',
          target: '_self',
          interna: 0,
          subitems: []
        },
        {
          boton: 'Cursos',
          link: 'https://niufleex.com',
          target: '_blank',
          interna: 0,
          subitems: [
            {
              boton: 'Área de Conocimiento 1',
              link: 'https://niufleex.com',
              target: '_blank',
              interna: 0
            },
            {
              boton: 'Área de Conocimiento 2',
              link: 'https://niufleex.com',
              target: '_blank',
              interna: 0
            },
            {
              boton: 'Área de Conocimiento 3',
              link: 'https://niufleex.com',
              target: '_blank',
              interna: 0
            }
          ]
        },
        {
          boton: 'Especialistas',
          link: 'https://niufleex.com',
          target: '_blank',
          interna: 0,
          subitems: []
        },
        {
          boton: 'Live',
          link: 'https://niufleex.com',
          target: '_blank',
          interna: 0,
          subitems: []
        }
      ]
    },
    logos: {
      type: Object,
      default: () => {
        return {
          logo1: {
            src:
              'https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none',
            link: '/explora/free',
            target: '_self'
          },
          logo2: {
            src:
              'https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png',
            link: '/explora/free',
            target: '_self'
          },
          avatar: {
            src: ''
          }
        }
      }
    }
  },
  data: () => {
    return {
      drawer: false,
      drawer2: false,
      group: null,
      group2: null,
      data: {
        firstname: '',
        firstsurname: '',
        secondsurname: '',
        email: '',
        username: ''
      },
      alert: { active: false }
    }
  },
  created () {
    // eslint-disable-next-line no-console
    console.log(this.$router)
    this.data = this.$storeNiu.getObjectValue('data') || {
      firstname: '',
      firstsurname: '',
      secondsurname: '',
      email: '',
      username: ''
    }
  },
  methods: {
    ...mapMutations(['SET_ALERT', 'SET_MODALSHOWQUIZ', 'SET_MODALCONTENTQUIZ']),

    firstname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'firstname')) {
          return data.firstname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    firstsurname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'firstsurname')) {
          return data.firstsurname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    genero () {
      try {
        let data = this.$storeNiu.getObjectValue('data')
        // this.showButton();
        // this.encuesta();
        if (Object.prototype.hasOwnProperty.call(data, 'genero')) {
          return data.genero
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    secondsurname () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'secondsurname')) {
          return data.secondsurname
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    username () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'username')) {
          return data.username
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    email () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'email')) {
          return data.email
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    customerid () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'customer_id')) {
          return data.customer_id
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    team () {
      try {
        let data = this.$storeNiu.getObjectValue('data')

        if (Object.prototype.hasOwnProperty.call(data, 'team_title')) {
          return data.team_title
        }
        return ''
        //eslint-disable-next-line
      } catch (error) {
        return ''
      }
    },
    cerrarSesion () {
      this.$emit('menufloat')
      this.$storeNiu.clear()
      this.$router.push('/login?logout=true')
      // window.location.href = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_LOGIN}?logout=true`;
      // window.location.href = `${process.env.VUE_APP_PROTOCOL}://app.niufleex.com?logout=true`;
    }
  },
  computed: {
    ...mapState(['membresia', 'modalShowQuiz', 'modalContentQuiz']),
    dataUser () {
      return this.$storeNiu.getObjectValue('data')
    },
    alto () {
      return window.innerHeight
    }
  }
}
</script>
