const progressTracks = [
  {
    trackId: 1,
    trackCode: 'Network-marketing',
    trackName: 'Network marketing',
    trackProgress: 90,
    color: {
      areaconocimientoAnguloColor: 45,
      areaconocimientoColor1: '#000',
      areaconocimientoColor2: '#FFF',
      fontColor: '#fff',
      mode: 'solid'
    },
    modules: [
      {
        id: 1,
        title: 'La industria y el canal de distribución',
        description:
          'Conoce el negocio del siglo XXI, los beneficios del canal de distribución y las habilidades para crecer tu negocio en el mundo del Network Marketing',
        progressValue: 33,
        areaconocimientoId: 1,
        areaconocimientoAnguloColor: 45,
        areaconocimientoColor1: '#000',
        areaconocimientoColor2: '#FFF'
      },
      {
        id: 2,
        title: 'Liderazgo en el Network Marketing',
        description:
          'En el mundo, la vida, los negocios y las organizaciones, todo nace y mueren por liderazgo. En las grandes organizaciones de Network Marketing, el LÍDER es el que marca la diferencia',
        progressValue: 70,
        areaconocimientoId: 2,
        areaconocimientoAnguloColor: 45,
        areaconocimientoColor1: '#000',
        areaconocimientoColor2: '#FFF'
      },
      {
        id: 8,
        title: 'El lenguaje interactivo de tu Navegador',
        description:
          'En el mundo, la vida, los negocios y las organizaciones, todo nace y mueren por liderazgo. En las grandes organizaciones de Network Marketing, el LÍDER es el que marca la diferencia',
        progressValue: 100,
        areaconocimientoId: 6,
        areaconocimientoAnguloColor: 45,
        areaconocimientoColor1: '#000',
        areaconocimientoColor2: '#FFF'
      },
      {
        id: 8,
        title: 'El lenguaje interactivo de tu Navegador',
        description:
          'En el mundo, la vida, los negocios y las organizaciones, todo nace y mueren por liderazgo. En las grandes organizaciones de Network Marketing, el LÍDER es el que marca la diferencia',
        progressValue: 100,
        areaconocimientoId: 6,
        areaconocimientoAnguloColor: 45,
        areaconocimientoColor1: '#000',
        areaconocimientoColor2: '#FFF'
      },
      {
        id: 8,
        title: 'El lenguaje interactivo de tu Navegador',
        description:
          'En el mundo, la vida, los negocios y las organizaciones, todo nace y mueren por liderazgo. En las grandes organizaciones de Network Marketing, el LÍDER es el que marca la diferencia',
        progressValue: 100,
        areaconocimientoId: 6,
        areaconocimientoAnguloColor: 45,
        areaconocimientoColor1: '#000',
        areaconocimientoColor2: '#FFF'
      }
    ]
  }
]

export default progressTracks
