<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Historial de membresías</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Tipo de membresía</th>
                      <th class="text-left">Fecha de activación</th>
                      <th class="text-left">Fecha de vencimiento</th>
                      <th class="text-left">Orden</th>
                      <th class="text-left">Código de acceso</th>
                      <th class="text-left">Días restantes</th>
                      <th class="text-left">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in historial"
                      :key="item.membership + '_' + index"
                    >
                      <td>{{ item.membership }}</td>
                      <td>{{ item.fecha_activacion.substring(0, 10) }}</td>
                      <td>{{ item.fecha_vencimiento.substring(0, 10) }}</td>
                      <td>{{ item.orden }}</td>
                      <td>
                        {{
                          item.codigo_acceso
                            ? item.codigo_acceso
                            : 'Código gratuito'
                        }}
                      </td>
                      <td>
                        {{ item.dias_restantes >= 0 ? item.dias_restantes : 0 }}
                      </td>

                      <td>{{ item.activo == 0 ? 'Vencido' : 'Activo' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    historial: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  methods: {
    close () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>

