/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import storeNiu from './plugins/storage';
import md5 from 'md5';
import {
    Vue2Storage
} from 'vue2-storage';
import findLocalIp from './plugins/ipprivada';
import Player from '@vimeo/player';
import VueI18n from 'vue-i18n';
import {
    Auth0Plugin
} from "./plugins/auth";
import {
    domain,
    clientId
} from "../auth_config.json";
Vue.use(VueI18n);

Vue.prototype.$Player = Player;
// import dotenv from 'dotenv'
// dotenv.config({ debug: process.env.DEBUG })

Vue.config.productionTip = false;
Vue.prototype.$setIntervalCurrentTime = null;
Vue.prototype.$clearIntervalCurrentTime = (clearIntervalMain) => {
    console.log(clearIntervalMain.clearIntervalMain);
    clearInterval(clearIntervalMain.$setIntervalCurrentTime);
};

Vue.prototype.$intervalActiveEndPlayVideo = null;
Vue.prototype.$clearIntervalActiveEndPlayVideo = (clearIntervalMain) => {
    console.log(clearIntervalMain.$intervalActiveEndPlayVideo);
    clearInterval(clearIntervalMain.$intervalActiveEndPlayVideo);
};
// By default the project simulates the client application being hosted independently from the server
// These lines setup axios so all requests are sent to the backend server
// However, you can comment them and the site will behave as if both client and server were hosted in localhost:8080
// due to the proxy dev server configured in vue.config.js

// same as the Url the server listens to
// axios.defaults.withCredentials = true

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '//localhost:5000';
} else {
    axios.defaults.baseURL = `//${process.env.VUE_APP_API}`;
}
// axios.defaults.baseURL = '//server-1.niufleex.com'
// axios.defaults.headers.common['Authorization']=request.headers['Access-Control-Allow-Origin'] = 'https://niufleex.com'
// console.log(window.location.origin)
if (window.location.href == 'https://premium.niufleex.com/login') {
    window.location.href = 'https://app.niufleex.com/login';
}
axios.defaults.headers.common['Access-Control-Allow-Origin'] =
    window.location.origin;
axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
Vue.prototype.$gapi = window.gapi;
Vue.prototype.$http = axios;

Vue.prototype.$fbAsyncInit =
    typeof window.fbAsyncInit === 'function' ? window.fbAsyncInit : false;

var getBrowserInfo = function() {
    var ua = navigator.userAgent,
        tem,
        M =
        ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
            return tem
                .slice(1)
                .join(' ')
                .replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
};

Vue.prototype.gtag = window.gtag;
Vue.prototype.Vimeo = window.Vimeo;
Vue.prototype.$storeNiu = storeNiu;
Vue.prototype.$md5 = md5;
Vue.prototype.$getBrowserInfo = getBrowserInfo;
Vue.prototype.$findLocalIp = findLocalIp;
Vue.use(Vue2Storage, {
    prefix: 'app_',
    driver: 'local',
    ttl: 60 * 60 * 24 * 1000 * 30, // 30 días
});
import webapi from './services/webapi';
let $storeNiu = storeNiu;

function redirectLogin(next, redirect) {

    let login = '/login';

    if (redirect != '/login' && !window.location.search.includes("code=")) {
        redirect = redirect.replace('/', '');
        login = `${login}?redirect=${redirect}`;
    }

    if (window.location.host == 'premium.niufleex.com') {
        window.location.href = `https://app.niufleex.com${login}`;
    } else {
        next(login);
    }
}
router.beforeEach(async(to, from, next) => {
    // //to a donde va

    store.commit(
        "SET_VUETIFY",
        vuetify.userPreset.theme.themes[$storeNiu.getObjectValue("data") &&
            $storeNiu.getObjectValue("data").membership_id == 2 ? "dark" : "light"]
    );
    console.log('beforeEach', vuetify.userPreset.theme.themes[$storeNiu.getObjectValue("data") &&
        $storeNiu.getObjectValue("data").membership_id == 2 ? "dark" : "light"], store)
    console.group('router');
    console.log(from, to, to.fullPath, to.query);
    console.groupEnd('router');
    // eslint-disable-next-line no-debugger
    //debugger;
    var redirect = null;
    let validate_team = null;
    let verified_email = null;
    let acceso = null
    let orderid = null
    let query = '';
    if (to.query.access_token && to.query.user) {
        $storeNiu.set('token', to.query.access_token);
        $storeNiu.set('user', to.query.user);
    }
    if (to.query['redirect']) {

        redirect = decodeURIComponent(to.query.redirect);
        window.localStorage.setItem('redirect', redirect)
        console.log(redirect)
    } else {
        window.localStorage.removeItem('redirect')
    }
    if (to.query['validate_team']) {
        validate_team = to.query.validate_team;
    }
    if (to.query['verified_email']) {
        verified_email = to.query.verified_email;
    }

    if (verified_email) {
        query = `verified_email=${verified_email}`;
    }
    if (validate_team) {
        query = query != '' ? '&' : '';
        query = `validate_team=${validate_team}`;
    }
    if (to.query['acceso']) {
        acceso = to.query.acceso;
    }
    if (to.query['orderid']) {
        orderid = to.query.orderid;
    }
    let validarLocalStorage = !!$storeNiu.getTextOrInt('user') && !!$storeNiu.getTextOrInt('token');
    let validarRestantes = $storeNiu.getTextOrInt('restantes') < 0;
    let auth0 = window.location.search.includes("code=") ||
        window.location.search.includes("state=")
        // alert(to.fullPath)
    let paso = []
    if (to.name != 'login' && to.name != 'about' && to.name != 'masterclass' && auth0 == false) {

        paso.push('paso 1')
        if (validarLocalStorage || validarRestantes) {
            paso.push('paso 2')
            if ($storeNiu.getTextOrInt('user')) {
                paso.push('paso 3')
                try {
                    let result = await webapi($storeNiu.getTextOrInt('token'));

                    result = await result.post('users/ValidToken', {
                        username: $storeNiu.getTextOrInt('user'),
                        to_fullpath: to.fullPath,
                        from_fullpath: from.fullPath,
                        to_name: to.name,
                        from_name: from.name,
                        to_params_id: to.params.id || '',
                        to_params_track: to.params.track || '',
                        origin: window.location.origin,
                    });
                    let token_premium = $storeNiu.getTextOrInt('token');
                    let user_pre = $storeNiu.getTextOrInt('user');

                    if (result.data) {
                        paso.push('paso 4')
                        $storeNiu.set('uservalid', result.data);
                        $storeNiu.set('restantes', result.data.dias_restantes);
                        $storeNiu.set('token', result.data.token);
                        $storeNiu.set('LOAD_PAYPERVIEW', result.data.track_id);
                        $storeNiu.set('LOAD_TEAM', result.data.team_code);
                        $storeNiu.set('LOAD_TEAMTITLE', result.data.team_title);
                        $storeNiu.set('data', result.data);
                        store.commit('SET_MEMBRESIA', result.data.membership_id);
                        store.commit('SET_LOAD_PAYPERVIEW', result.data.track_id);

                        store.commit('users', result.data);

                        if (result.data.dias_restantes >= 0) {
                            paso.push('paso 5')

                            if (to.query.access_token) {
                                paso.push('paso 6')
                                if (result) {
                                    paso.push('paso 7')
                                    window.gtag('set', {
                                        user_id: $storeNiu.getTextOrInt('user'),
                                    }); // Establezca el ID de usuario mediante el user_id con el que haya iniciado sesión.
                                }
                                if (
                                    result.data.membership_id == 1 ||
                                    result.data.membership_id == 0
                                ) {
                                    paso.push('paso 8')
                                    if (window.location.host == process.env.VUE_APP_PREMIUM && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                        paso.push('paso 9')

                                        //window.location.href = `https://app.niufleex.com/${redirect}?access_token=${token_premium}&user=${user_pre}&${query}`;
                                        next(`/${redirect}?${query}`);
                                    } else {
                                        paso.push('paso 10')
                                        if (window.location.host == process.env.VUE_APP_PRO && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                            paso.push('paso 11')
                                            if (redirect) {
                                                paso.push('paso 12')
                                                next(`/${redirect}?${query}`);
                                            } else {
                                                paso.push('paso 13')
                                                next();
                                            }
                                        } else {
                                            if (redirect) {
                                                paso.push('paso 14')
                                                next(`/${redirect}?${query}`);
                                            } else {
                                                paso.push('paso 15')
                                                next();
                                            }
                                        }
                                    }
                                } else {
                                    paso.push('paso 16')
                                    if (result.data.membership_id == 2) {
                                        paso.push('paso 17')
                                        if (window.location.host == process.env.VUE_APP_PRO && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                            paso.push('paso 18')
                                                //window.location.href = `https://${process.env.VUE_APP_PREMIUM}/${redirect}?access_token=${token_premium}&user=${user_pre}&${query}`;
                                            next(`/${redirect}?${query}`);
                                        } else {
                                            paso.push('paso 19')
                                            if (window.location.host == process.env.VUE_APP_PREMIUM && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                                paso.push('paso 20')

                                                if (redirect) {
                                                    paso.push('paso 21')
                                                    next(`/${redirect}?${query}`);
                                                } else {
                                                    paso.push('paso 22')
                                                    next();
                                                }
                                            } else {
                                                paso.push('paso 23')
                                                if (window.location.host == 'test.niufleex.com') {
                                                    paso.push('paso 24')
                                                    if (redirect) {
                                                        paso.push('paso 25')
                                                        next(`/${redirect}?${query}`);
                                                    } else {
                                                        paso.push('paso 26')
                                                        next();
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (
                                    result.data.membership_id == 1 ||
                                    result.data.membership_id == 0
                                ) {
                                    paso.push('paso 27')
                                    console.log(window.location);
                                    if (window.location.host == process.env.VUE_APP_PREMIUM) {
                                        paso.push('paso 28')
                                        if (query != '') {
                                            query = '&' + query
                                        }
                                        window.location.href = `https://${process.env.VUE_APP_PRO}/${redirect}?access_token=${token_premium}&user=${user_pre}${query}`;
                                    } else {
                                        paso.push('paso 29')
                                        if (window.location.host == process.env.VUE_APP_PRO) {
                                            paso.push('paso 30')
                                            if (redirect) {
                                                paso.push('paso 31')
                                                next(`/${redirect}?${query}`);
                                            } else {
                                                paso.push('paso 32')
                                                next();
                                            }
                                        } else {
                                            paso.push('paso 33')
                                            if (redirect) {
                                                paso.push('paso 34')
                                                next(`/${redirect}?${query}`);
                                            } else {
                                                paso.push('paso 35')
                                                next();
                                            }
                                        }
                                    }
                                } else {
                                    paso.push('paso 36')
                                    if (result.data.membership_id == 2) {
                                        paso.push('paso 37')
                                        if (window.location.host == process.env.VUE_APP_PRO && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                            paso.push('paso 38')
                                            if (query != '') {
                                                query = '&' + query
                                            }
                                            window.localStorage.setItem('redirectredirectredirectredirectredirect', redirect)
                                            redirect = window.localStorage.getItem('redirect')
                                            window.location.href = `https://${process.env.VUE_APP_PREMIUM}/${redirect}?access_token=${token_premium}&user=${user_pre}${query}`;
                                        } else {
                                            paso.push('paso 39')
                                            if (window.location.host == process.env.VUE_APP_PREMIUM && process.env.VUE_APP_PREMIUM != process.env.VUE_APP_PRO) {
                                                paso.push('paso 40')
                                                if (redirect) {
                                                    paso.push('paso 41')
                                                    next(`/${redirect}?${query}`);
                                                } else {
                                                    paso.push('paso 42')
                                                    next();
                                                }
                                            } else {
                                                paso.push('paso 43')
                                                if (redirect) {
                                                    paso.push('paso 44')
                                                    next(`/${redirect}?${query}`);
                                                } else {
                                                    paso.push('paso 45')
                                                    next();
                                                }
                                            }
                                        }
                                    } else {
                                        paso.push('paso 46')
                                        redirectLogin(next, to.fullPath);
                                    }
                                }
                            }
                        } else {
                            paso.push('paso 47')
                            if ($storeNiu.getTextOrInt('LOAD_PAYPERVIEW') > 0) {
                                paso.push('paso 48')

                                if (to.name == 'yo') {
                                    paso.push('paso 49')
                                    next();
                                } else {
                                    paso.push('paso 50')
                                    if (to.name == 'changePassword') {
                                        paso.push('paso 51')
                                        next();
                                    } else {
                                        paso.push('paso 52')
                                        if (to.name != 'payperview') {
                                            paso.push('paso 53')
                                            next(`/payperview`);
                                        } else {
                                            paso.push('paso 54')
                                            next();
                                        }
                                    }
                                }
                            } else {
                                paso.push('paso 55')
                                if (to.name != 'yo') {
                                    paso.push('paso 56')
                                    if ($storeNiu.getTextOrInt('team') != 'null') {
                                        paso.push('paso 57')
                                        next();
                                    } else {
                                        paso.push('paso 58')
                                        next(`/yo/${user_pre}?renueva=membresia`);
                                    }
                                } else {
                                    paso.push('paso 59')
                                    next();
                                }
                            }
                        }
                    } else {
                        paso.push('paso 60')
                        $storeNiu.removeKey('user');
                        $storeNiu.removeKey('token');
                        redirectLogin(next, to.fullPath);
                    }
                } catch (error) {
                    paso.push('paso 61')
                    $storeNiu.removeKey('user');
                    $storeNiu.removeKey('token');
                    redirectLogin(next, to.fullPath);
                }
            } else {
                paso.push('paso 62')
                $storeNiu.removeKey('user');
                $storeNiu.removeKey('token');
                redirectLogin(next, to.fullPath);
            }
        } else {
            paso.push('paso 63')
            if (
                validarRestantes &&
                to.name != 'yo' &&
                !isNaN(parseInt($storeNiu.getTextOrInt('restantes')))
            ) {
                paso.push('paso 64')
                next(`/yo/${$storeNiu.getTextOrInt('user')}?renueva=membresia`);
            } else {
                paso.push('paso 65')
                if (!validarLocalStorage) {
                    paso.push('paso 66')
                    $storeNiu.removeKey('user');
                    $storeNiu.removeKey('token');
                    redirectLogin(next, to.fullPath);

                } else {
                    paso.push('paso 67')
                    next();
                }
            }
        }
    } else {
        paso.push('paso 68')
        $storeNiu.removeKey('user');
        $storeNiu.removeKey('token');
        // alert(to.fullPath)
        next();
    }
    window.pasopop = paso
        // alert(paso.pop())
        // console.log($storeNiu.getTextOrInt('user'), $storeNiu.getTextOrInt('token'))
});

const messages = {
    en: {
        message: {
            hello: 'hello world',
        },
    },
    es: {
        message: {
            hello: 'Hola Mundo',
        },
    },
    ja: {
        message: {
            hello: 'こんにちは、世界',
        },
    },
};
const i18n = new VueI18n({
    locale: storeNiu.getTextOrInt('locale') || 'es', // set locale
    messages, // set locale messages
});
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname
        );
        // console.log(appState)
    }
});
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
    created() {
        // The configuration of the plugin can be changed at any time.
        // Just call the setOptions method and pass the object with the settings to it.
        this.$storage.setOptions({
            prefix: 'app_',
            driver: 'local',
            ttl: 60 * 60 * 24 * 1000 * 30, // 30 días
        });
    },
}).$mount('#app');