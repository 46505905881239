<template>
	<v-dialog v-model="dialog" persistent max-width="450">
		<v-form ref="form_activar" @submit.prevent="btnAccion">
			<v-card>
				<v-card-title class="headline"> ¿Estás Segur@? </v-card-title>
				<v-card-text>
					"Al continuar con la eliminación de la cuenta, se eliminará tu acceso
					a la plataforma de NIUFLEEX.COM. <br />
					Para ingresar a la plataforma nuevamente deberás realizar el proceso
					de registro en
					<a href="//registro.niufleex.com/free"> Registro Usuario Free </a>
					<br />
					"
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="purple darken-1"
						:loading="loadingActivar"
						text
						type="submit"
						>Continuar con la Eliminarción</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>
<script>
export default {
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		alertErrorConexion: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		if (this.$route.query.orderid) {
			this.activar.orderID = parseInt(this.$route.query.orderid);
		}
		if (this.$route.query.acceso) {
			this.activar.code = this.$route.query.acceso;
		}
	},
	methods: {
		close({ cerraMoral = false, dialog = false }) {
			this.$emit("closeDialogEliminarCuenta", { dialog, cerraMoral });
		},
		async btnAccion() {
			if (this.alertErrorConexion) {
				this.$emit("showAlert");
			} else {
				if (this.$refs.form_activar.validate()) {
					try {
						this.loadingActivar = true;

						let activar = (await this.$http.post("/users/delete")).data;
						// eslint-disable-next-line no-console
						console.log(activar);
						if (activar.status && activar.status.codigo == "202") {
							this.$store.commit("alert", {
								type: "success",
								message: "Cuenta eliminada",
								snackbar: true,
							});
							this.close({ cerraMoral: true });
							this.loadingActivar = false;
							this.$nextTick(() => {
								setTimeout(() => {
									this.$store.commit("alert", {
										type: "success",
										message: "Cuenta Eliminada",
										snackbar: true,
									});
									this.$emit("menufloat");
									this.$storeNiu.clear();
									this.$router.push("/");
								}, 500);
							});
						} else {
							throw "Error";
						}
						// eslint-disable-next-line no-console
						console.log(activar);
					} catch (error) {
						// eslint-disable-next-line no-console
						this.$store.commit("alert", {
							type: "error",
							message: "Error, inténtelo nuevamente o verifique sus datos",
							snackbar: true,
						});
						this.loadingActivar = false;
					}
				}
			}
		},
	},
	data: () => {
		return {
			loadingActivar: false,
			activar: {
				code: null,
				orderID: null,
			},
			rules: {
				code: [(v) => !!v || "Código de acceso requerido"],
				nrocompra: [(v) => !!v || "Nro de compra requerido"],
			},
		};
	},
};
</script>
<style lang="scss" scoped>
.pronto {
	position: absolute;
	top: 0;
	background-color: red;
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 0.7rem;
	right: 0;
}

.activo {
	position: absolute;
	top: 0;
	background-color: green;
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 0.7rem;
	right: 0;
}
</style>
