<template>
	<v-navigation-drawer
		style="color: white !important; z-index: 8"
		color="primary"
		left
		v-model="primaryDrawer.model"
		:clipped="primaryDrawer.clipped"
		:floating="true"
		temporary
		app
		overflow
		:disable-route-watcher="false"
	>
		<v-list class="py-0 listMenu" dense nav style="color: white !important">
			<v-list-item class="text-right">
				<label class="text-right" @click="primaryDrawer.model = false">
					<v-icon right> mdi-close-thick </v-icon>
				</label>

				<!-- <v-list-item-avatar right @click="primaryDrawer.model = false">
        </v-list-item-avatar> -->
				<!-- <v-list-item-content>
          <v-list-item-title>  </v-list-item-title>
        </v-list-item-content> -->
			</v-list-item>
			<v-divider></v-divider>
			<v-list-item
				style="color: white !important"
				v-if="$storeNiu.getTextOrInt('LOAD_PAYPERVIEW') != -1"
			>
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/payperview`"> Pay Per View </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<!-- <v-list-item style="color: white !important" v-if="membresia == 0">
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/compraahora`"> Compra Ahora </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider> -->
			<!-- <v-list-item style="color: white !important" v-if="membresia == 0">
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/navega`"> Explora Premium </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider> -->
			<v-list-item style="color: white !important" v-if="membresia == 0">
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/live`"> LIVE </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<!-- <v-list-item style="color: white !important" v-if="membresia == 0">
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/explora-free`"> EXPLORA FREE </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider> -->
			<!-- <v-list-item
				style="color: white !important"
				v-if="
					($storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code) &&
					membresia == 0
				"
			>
				<v-list-item-content>
					<v-list-item-title>
						<router-link link :to="`/holter`"> HOLTER </router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider> -->
			<v-list-item
				style="color: white !important"
				v-if="$storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code"
			>
				<v-list-item-content>
					<v-list-item-title>
						<router-link
							link
							:to="`${
								load_team.team_code == 'elite' ||
								$storeNiu.getTextOrInt('LOAD_TEAM') == 'elite'
									? '/teams/'
									: '/team/'
							}${$storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code}`"
							v-if="$storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code"
						>
						{{
							$storeNiu.getTextOrInt("LOAD_TEAMTITLE") || load_team.team_title
						}}
						</router-link>
					</v-list-item-title>
					<!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
					<v-list-item-subtitle> </v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<template
				v-for="item in menu_main.filter((ele) =>
					ele.membresia.includes(membresia)
				)"
			>
				<v-list-item
					style="color: white !important"
					:key="'menuleft_float' + item.title"
				>
					<v-list-item-content>
						<v-list-item-title>
							<router-link :to="item.link">
								<span v-if="item.title">
									{{ item.title.toUpperCase() }}
								</span>
								<img v-else :src="item.img" alt="" />
							</router-link>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider :key="item.title"></v-divider>
			</template>
			<v-list-item style="color: white !important">
				<v-list-item-content>
					<v-list-item-title>
						<v-btn
							target="_blank"
							dark
							href="https://niufleex.com/terminos-y-condiciones/"
						>
							Terminos y Condiciones
						</v-btn>
					</v-list-item-title>
					<!-- <v-text-field label="Algo para cambiar"></v-text-field> -->
					<v-list-item-subtitle>
						<v-btn
							dark
							target="_blank"
							href="https://niufleex.com/politicas-de-privacidad/"
						>
							Polícitas de Privacidad
						</v-btn>
					</v-list-item-subtitle>
					<!-- <v-text-field label="Correo Electrónico"></v-text-field> -->
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list-item style="color: white !important" v-if="obtenerCodigo">
				<v-list-item-icon>
					<v-icon> mdi-gift-open-outline </v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>
						<!-- <v-text-field label="Email"
            placeholder="Email regalo" v-model="emailregalo" clearable></v-text-field> -->
						<v-btn @click="enviarregalo">Obtener Código</v-btn>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item style="color: white !important" v-if="urlcode">
				<v-list-item-icon @click="copiar">
					<v-icon> mdi-content-copy </v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>
						<a :href="urlcode" id="code_share">{{ urlcode }}</a>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-alert v-model="alert.active" :timeout="3000" outlined color="purple">
			<div>Copiado el link</div>
		</v-alert>
	</v-navigation-drawer>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
	props: {
		primaryDrawer: {
			type: Object,
			default: function () {
				return {
					model: null,
					clipped: false,
					floating: false,
					mini: false,
					urlcode: null,
				};
			},
		},
		load_team: {
			type: Object,
			default: function () {
				return {
					team_code: null,
				};
			},
		},
		membresia: {
			type: Number,
			default: 0,
		},
		menu_main: {
			type: Array,
			default: [],
		},
	},
	created() {
		this.data = this.$storeNiu.getObjectValue("data") || {
			firstname: "",
			firstsurname: "",
			secondsurname: "",
			email: "",
			username: "",
		};
	},
	computed: {
		...mapState(["modalShowQuiz", "modalContentQuiz"]),
	},
	methods: {
		...mapMutations(["SET_ALERT", "SET_MODALSHOWQUIZ", "SET_MODALCONTENTQUIZ"]),
		encuesta() {
			// try {
			//   let config = {
			//     headers: {
			//       Authorization: `Bearer ${this.$storeNiu.getTextOrInt('token') ||
			//         this.token}`
			//     }
			//   }
			//   this.$http
			//     .post('/Encuestas/getEncuestaGeneral', config)
			//     .then(encuesta => {
			//       // eslint-disable-next-line no-console
			//       console.log(encuesta)
			//       if (
			//         encuesta &&
			//         encuesta.data &&
			//         encuesta.data.questions &&
			//         encuesta.data.questions.length > 0
			//       ) {
			//         this.SET_MODALSHOWQUIZ(true)
			//         this.SET_MODALCONTENTQUIZ(encuesta.data)
			//       }
			//     })
			//   // eslint-disable-next-line no-empty
			// } catch (error) {}
		},
		async showButton() {
			try {
				let config = {
					headers: {
						Authorization: `Bearer ${
							this.$storeNiu.getTextOrInt("token") || this.token
						}`,
					},
				};
				let show = (
					await this.$http.post("/users/showButtonShareMembresia", config)
				).data;

				this.obtenerCodigo = show;
				// eslint-disable-next-line no-empty
			} catch (error) {}
		},

		copiar() {
			// eslint-disable-next-line no-console
			// console.log(document.getElementById(id), id)
			var aux = document.createElement("div");
			aux.setAttribute("contentEditable", true);
			aux.innerHTML = document.getElementById("code_share").innerHTML;
			aux.setAttribute(
				"onfocus",
				"document.execCommand('selectAll',false,null)"
			);
			document.body.appendChild(aux);
			aux.focus();
			document.execCommand("copy");
			document.body.removeChild(aux);
			this.alert.active = true;

			setTimeout(() => {
				this.alert.active = false;
			}, 3000);
			this.SET_ALERT({
				message: "Link Copiado",
				type: "success",
				snackbar: true,
			});
		},
		async enviarregalo() {
			let codigos = (await this.$http.post(`/registro/getCodePresentShare`))
				.data[0];

			// eslint-disable-next-line no-console
			console.log(codigos);

			this.urlcode = `https://registro.niufleex.com/registro/${codigos.code_acceso}/${codigos.code_orden}`;
		},
		firstname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");
				this.showButton();
				this.encuesta();
				if (Object.prototype.hasOwnProperty.call(data, "firstname")) {
					return data.firstname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		firstsurname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "firstsurname")) {
					return data.firstsurname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		secondsurname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "secondsurname")) {
					return data.secondsurname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		username() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "username")) {
					return data.username;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		email() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "email")) {
					return data.email;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		team() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "team_title")) {
					return data.team_title;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		cerrarSesion() {
			this.$emit("menufloat");
			this.$storeNiu.clear();
			this.$router.push({ path: "/login?logout=true" });
		},
	},
	data() {
		return {
			urlcode: null,
			items: [],
			alert: { active: false },
			obtenerCodigo: false,
			data: {
				firstname: "",
				firstsurname: "",
				secondsurname: "",
				email: "",
				username: "",
			},
		};
	},
};
</script>
<style lang="scss" scoped>
$fondo: white;
.niu-dark {
	background-color: $fondo !important;
}
.listMenu,
.listMenu * {
	color: white !important;
}

.listMenu a {
	text-decoration: none !important;
}
</style>
