<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <v-form ref="form_activar" @submit.prevent="activarMembresia">
      <v-card>
        <v-card-title class="headline">
          Renueva tu membresía
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Número Orden (#Orden)"
                    v-model="activar.orderID"
                    filled
                    color="#ffffff"
                    type="number"
                    :rules="rules.nrocompra"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- cols="10"> -->
                  <v-textarea
                    label="Código de acceso"
                    v-model="activar.code"
                    filled
                    color="#ffffff"
                    :rules="rules.code"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple darken-1"
            :loading="loadingActivar"
            text
            type="submit"
            >Activar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    alertErrorConexion: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.$route.query.orderid) {
      this.activar.orderID = parseInt(this.$route.query.orderid)
    }
    if (this.$route.query.acceso) {
      this.activar.code = this.$route.query.acceso
    }
  },
  methods: {
    close ({ actualizarHistorial = false, dialog = false }) {
      this.$emit('closeDialogRenueva', { dialog, actualizarHistorial })
    },
    async activarMembresia () {
      if (this.alertErrorConexion) {
        this.$emit('showAlert')
      } else {
        if (this.$refs.form_activar.validate()) {
          try {
            this.loadingActivar = true

            let activar = (
              await this.$http.post('/Registro/renovar', {
                ...this.activar,
                orderID: parseInt(this.activar.orderID)
              })
            ).data
            if (activar.codigo == '202') {
              this.$store.commit('alert', {
                type: 'success',
                message: 'Membresía Ingresada',
                snackbar: true
              })
              this.close({ actualizarHistorial: true })
              this.loadingActivar = false
              this.$nextTick(() => {
                this.$store.commit('alert', {
                  type: 'success',
                  message: 'Inicia sesión nuevamente',
                  snackbar: true
                })
                this.$emit('menufloat')
                this.$storeNiu.clear()
                this.$router.push('/')
              })
            } else {
              throw 'Error'
            }
            // eslint-disable-next-line no-console
            console.log(activar)
          } catch (error) {
            // eslint-disable-next-line no-console
            this.$store.commit('alert', {
              type: 'error',
              message: 'Error, inténtelo nuevamente o verifique sus datos',
              snackbar: true
            })
            this.loadingActivar = false
          }
        }
      }
    }
  },
  data: () => {
    return {
      loadingActivar: false,
      activar: {
        code: null,
        orderID: null
      },
      rules: {
        code: [v => !!v || 'Código de acceso requerido'],
        nrocompra: [v => !!v || 'Nro de compra requerido']
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
