/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable no-empty */
import axios from 'axios';

const webapi = async(token = '') => {
    let baseURL = '//';
    if (process.env.NODE_ENV == 'development') {
        // baseURL = '//api.niufleex.com'
        baseURL = `//${process.env.VUE_APP_API}`;
        //  baseURL = "//api-ux-test.niufleex.com";

        // baseURL = '//app.niufleex.net' 
    } else {
        let test = process.env.VUE_APP_TEST == 'false' ? false : true;
        if (test) {
            baseURL = '//app.niufleex.net';
        } else {
            baseURL = `//${process.env.VUE_APP_API}`;
        }
    }

    // baseURL = '//balance.niufleex.com';

    if (token == '') {
        return axios.create({
            baseURL,
        });
    } else {
        return axios.create({
            baseURL,
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
    }
};


export default webapi;