<template>
  <v-dialog v-model="dialogModuloPremium" persistent max-width="700">
    <v-btn style="position:absolute;z-index: 1;" icon dark @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <router-link
      class="elevation-0"
      :to="`/yo/${$storeNiu.getTextOrInt('user')}?renueva=membresia`"
    >
      <v-img
        class="elevation-0"
        src="https://i.postimg.cc/4sFDhz2v/popup.jpg"
        :to="`/yo/${$storeNiu.getTextOrInt('user')}?renueva=membresia`"
      >
      </v-img>
    </router-link>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialogModuloPremium: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {}
  },
  methods: {
    close () {
      this.$emit('CloseDialogModuloPremium')
    }
  }
}
</script>
