<template>
  <!-- <v-dialog v-model="loading" persistent >
    <v-progress-linear
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </v-dialog> -->
  <div></div>
</template>
<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
