<template>
	<v-bottom-navigation
		fixed
		hide-on-scroll
		horizontal
		class="level-30 v-application primary pa-3"
		color="primary"
		scroll-target="#hide-on-scroll-example"
		shift
	>
		<template v-for="item in menuBottom.filter((ele) => !!ele.img)">
			<v-btn
				color="white"
				text
				:to="item.link"
				v-if="
					item.membresia &&
					item.membresia.length > 0 &&
					item.membresia.includes(membresiaBottom)
				"
				:key="'bottom-' + item.title"
				style="text-align: center"
				class="mr-2"
			>
				<span> {{ item.title_little }} </span>
				<img
					class="v-icon v-icon"
					:src="item.img"
					height="35px"
					:alt="item.title"
				/>
			</v-btn>
		</template>
	</v-bottom-navigation>
</template>
<script>
export default {
	components: {},
	props: {
		menuBottom: {
			type: Array,
		},
		membresiaBottom: {
			type: Number,
			default: 0,
		},
	},
};
</script>
<style lang="scss" scoped>
.level-6,
.v-item-group.v-bottom-navigation--fixed.level-6 {
	z-index: 6;
}
.level-30,
.v-item-group.v-bottom-navigation--fixed.level-30 {
	z-index: 30;
}
.v-item-group.v-bottom-navigation--shift
	.v-btn--active
	.v-btn__content
	> *:not(.v-icon) {
	top: 27px;
}
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
	opacity: 0;
}
.v-item-group.v-bottom-navigation--horizontal
	.v-btn
	> .v-btn__content
	> .v-icon {
	margin-right: auto;
}
</style>
