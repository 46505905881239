/* eslint-disable no-debugger */
/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import Yo from "../views/Yo.vue";
import Modulo from "../views/Modulo";

Vue.use(VueRouter);
const routes = [{
        path: "/",

        redirect: "/login",
    },
    {
        name: 'free',
        path: '/explora/free',
        component: () =>
            import (
                /* webpackChunkName: "reproductor" */
                '../views/explora_free.vue'
            ),
    },
    {
        name: 'premium',
        path: '/explora/premium',
        component: () =>
            import (
                /* webpackChunkName: "reproductor" */
                '../views/explora_premium.vue'
            )
    },
    // {
    // 	path: '/about',
    // 	name: 'about',
    // 	// route level code-splitting
    // 	// this generates a separate chunk (about.[hash].js) for this route
    // 	// which is lazy-loaded when the route is visited.
    // 	component: () =>
    // 		import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // },
    {
        name: "login",
        path: "/login",
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
        name: "play",
        path: "/play/:guid",
        component: () =>
            import (
                /* webpackChunkName: "reproductor" */
                "../views/reproductor.vue"
            ),
    },
    {
        name: "reproductor",
        path: "/reproductor/:guid",
        component: () =>
            import (
                /* webpackChunkName: "reproductor" */
                "../views/reproductor.vue"
            ),
    },
    {
        name: "reproductor2",
        path: "/reproductor2/",
        component: () =>
            import (
                /* webpackChunkName: "reproductor" */
                "../views/reproductor2.vue"
            ),
    },
    {
        name: "live",
        path: "/live",
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/Newlive.vue"),
    },
    {
        name: "ver_curso",
        path: "/curso/:guid",
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/ver_curso.vue"),
    },
    {
        name: "modulo",
        path: "/modulo/:track/:id",
        component: Modulo, //() => import(/* webpackChunkName: "modulo" */ ),
    },
    {
        name: "modulo_",
        path: "/modulo_/:track/:id",
        component: Modulo, //() => import(/* webpackChunkName: "modulo" */ ),
    },
    // {
    // 	name: 'sesion',
    // 	path: '/sesion/:code/:id',
    // 	component: () => import(/* webpackChunkName: "sesion" */ '../views/Sesion'),
    // },
    {
        name: "holter",
        path: "/holter",
        component: () =>
            import ( /* webpackChunkName: "holter" */ "../views/FormularioHolter"),
    },
    {
        name: "ordenes",
        path: "/ordenes",
        component: () =>
            import ( /* webpackChunkName: "holter" */ "../views/Ordenes"),
    },
    {
        name: "Active",
        path: "/active",
        component: () =>
            import ( /* webpackChunkName: "holter" */ "../views/Active"),
    },
    {
        name: "explora",
        path: "/explora",
        redirect: "/newexplora-pro",
    },
    {
        name: "payperview",
        path: "/payperview",
        component: () =>
            import ( /* webpackChunkName: "explora" */ "../views/PayPerView"),
    },
    {
        name: "explora_premium",
        path: "/explora-premium",
        redirect: "/newexplora",
    },
    {
        name: "changepassword",
        path: "/change",
        component: () =>
            import (
                /* webpackChunkName: "changePassword" */
                "../views/CambiarPassword.vue"
            ),
    },
    {
        name: "universo",
        path: "/universo",
        component: () =>
            import ( /* webpackChunkName: "universo" */ "../views/Universo.vue"),
    },
    {
        name: "progreso",
        path: "/progreso",
        redirect: "/miaprendizaje",
    },
    {
        name: "yo",
        path: "/yo/:username",
        component: Yo,
    },
    {
        name: "sesion",
        path: "/sesion",
        component: () =>
            import ( /* webpackChunkName: "sesion" */ "../views/Sesion.vue"),
    },
    {
        name: "old_live",
        path: "/old_live",
        component: () =>
            import ( /* webpackChunkName: "live" */ "../views/Live.vue"),
    },
    {
        name: "miaprendizaje",
        path: "/miaprendizaje",
        component: () =>
            import (
                /* webpackChunkName: "miaprendizaje" */
                "../views/MiAprendizaje.vue"
            ),
    },
    {
        name: "track",
        path: "/track/:track/:id",
        component: () =>
            import (
                /* webpackChunkName: "newmodulo :id" */
                "../views/NewModulosAndSesiones.vue"
            ),
    },
    {
        name: "track",
        path: "/track/:track",
        component: () =>
            import (
                /* webpackChunkName: "newmodulo" */
                "../views/NewModulosAndSesiones.vue"
            ),
    },
    {
        name: "miruta",
        path: "/miruta/:track",
        component: () =>
            import (
                /* webpackChunkName: "newmodulo" */
                "../views/NewModulosAndSesiones_MiRuta.vue"
            ),
    },
    {
        name: "newexplora",
        path: "/newexplora",
        component: () =>
            import ( /* webpackChunkName: "newexplora" */ "../views/NewExplora.vue"),
    },
    {
        name: "newexplora-pro",
        path: "/newexplora-pro",
        component: () =>
            import (
                /* webpackChunkName: "newexplora" */
                "../views/NewExplora_pro.vue"
            ),
    },
    {
        name: "teams",
        path: "/team/:team",
        component: () =>
            import (
                /* webpackChunkName: "newexplora" */
                "../views/NewExplora_teams.vue"
            ),
    },
    {
        name: "explora_team_elite",
        path: "/teams/:team",
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/explora_teams.vue"),
    },
    {
        name: "navega",
        path: "/navega",
        component: () =>
            import (
                /* webpackChunkName: "newexplora" */
                "../views/NewExplora_Vitrina.vue"
            ),
    },
    {
        name: "compraahora",
        path: "/compraahora",
        component: () =>
            import (
                /* webpackChunkName: "newexplora" */
                "../views/Compra_Ahora.vue"
            ),
    },
    {
        name: "explora-free",
        path: "/explora-free",
        component: () =>
            import (
                /* webpackChunkName: "newexplora" */
                "../views/NewExplora_free.vue"
            ),
    },
    {
        path: "*",
        name: "not-found",
        component: () =>
            import ( /* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;