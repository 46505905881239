import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#541983', // '#4d1552',
                secondary: '#ff8b02',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#f5f5f5',
                footertext: '#b26fe7',
                backcontinuar: '#6a289f',
                backFooterUno: '#4b0b7f',
                backFooterDos: '#541983',
                backCardModulos: '#5a069e',
                backPlayer: '#4b0b7f',
                backMenu: '#541983',
                title: '#3e026e',
                btnFicha: '#8208b1',
            },
            dark: {
                primary: '#272429',
                secondary: '#ff8b02',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#000',
                footertext: '#000',
                backcontinuar: '#272429',
                backFooterUno: '#272429',
                backFooterDos: '#343036',
                backCardModulos: '#000',
                backPlayer: 'rgb(21 21 21)',
                backMenu: '#272429',
                title: '#272429',
                btnFicha: '#272429'
            },
        },
    },
    breakpoint: {
        scrollBarWidth: 24,
        mobileBreakpoint: 'sm' // This is equivalent to a value of 960
    },
    // icons: {
    //   iconfont: 'mdi', // default - only for display purposes
    // },
});