import webapi from '../../services/webapi'
const carruseles = {
  namespaced: true,
  state: {
    modulo: null,
    token: null,
    trackId: null,
    videonumber:0
  },
  mutations: {
    SET_MODULO (state, newValue) {
      state.modulo = newValue
    },
    SET_TOKEN (state, newValue) {
      state.token = newValue
    },
    SET_TRACKID (state, newValue) {
      state.trackId = newValue
    },
    SET_VIDEONUMBER(state,newValue){
      state.videonumber = newValue
    }
  },
  actions: {
    setModulo ({ commit, state }, newValue) {
      commit('SET_MESSAGE', newValue)
      return state.modulo
    },
    loadCarruseles ({ commit, state }) {
      webapi(state.token)
        .get(`/Modulos/Trackid/${state.trackId}`)
        .then(response => {
          commit('SET_MODULO', response.data)
        })
        //eslint-disable-next-line
        .catch(error => { })
      return state.modulo
    }
  },
  getters: {
    modulo: state => {
      return state.modulo
    }
  }
}
export default carruseles
