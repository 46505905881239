<template>
  <v-dialog
    :persistent="!enviado"
    :max-width="enviado ? 400 : 800"
    v-model="modalShowQuiz"
    class="elevation-2"
  >
    <v-card
      v-if="enviado"
      :style="`background-color:${$vuetify.theme.defaults.light.primary}`"
    >
      <v-card-text>
        <div>
          <v-btn
            :style="
              `position:absolute;z-index: 1; top:0;right:0;background-color:${$vuetify.theme.defaults.light.secondary} ;font-size:12px;`
            "
            icon
            dark
            @click="close"
            circle
            size="10"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3 class="pa-5">
            ¡Muchas gracias por tu respuesta! Te invito a que sigas siendo parte
            de la Re-Evolución Educativa con Niufleex.
          </h3>
        </div>
      </v-card-text>
    </v-card>
    <form v-else-if="modalContentQuiz && modalContentQuiz['quiz_title']!=''" @submit.prevent="enviarDatos">
      <v-card
        :style="`background-color:${$vuetify.theme.defaults.light.primary}`"
      >
        <v-card-title>
          <h3 v-html="modalContentQuiz.quiz_title"></h3>
        </v-card-title>
        <v-card-subtitle>
          <v-row justify="center">
            <v-col md="5" xl="4" sm="7" cols="8">
              <v-img
                class="rounded-lg"
                src="https://niufleex.com/wp-content/uploads/2021/09/GraficaEncuesta.png"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="9" cols="12">
              <h3 style="text-align:center;" v-html="modalContentQuiz.quiz_description"></h3>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-alert v-if="alert" dense outlined type="error">
            Debe contestar todo
          </v-alert>
          <v-row
            justify="center"
            v-for="(item, index) of modalContentQuiz.questions"
            :key="index"
          >
            <v-col cols="11">
              <h3 class="ma-3">
                <v-icon size="8">mdi-brightness-1</v-icon>
                {{ item.question_title }}
              </h3>
              <h5>{{ item.question_description }}</h5>
              <template v-if="item.question_type == 'ranking'">
                <v-rating
                  v-model="form[item.question_code]"
                  background-color="indigo lighten-3"
                  color="indigo"
                  large
                  length="5"
                  size="64"
                ></v-rating>
              </template>
              <template v-else-if="item.question_type == 'textfield'">
                <v-text-field v-model="form[item.question_code]">
                </v-text-field>
              </template>
              <template v-else-if="item.question_type == 'textarea'">
                <v-textarea
                  outlined
                  label="Comentarios"
                  v-model="form[item.question_code]"
                >
                </v-textarea>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="text-align:center; overflow:hidden;">
          <v-row justify="center">
            <v-col cols="3">
              <v-btn
                :color="$vuetify.theme.defaults.light.secondary"
                target="_blank"
                style="text-align:center;"
                type="submit"
              >
                Enviar Encuesta
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      heightFrame: 600,
      videoplayer: null,
      rating: 0,
      form: {},
      enviado: false,
      alert: false
    }
  },
  mounted () {},
  computed: {
    ...mapState(['modalShowQuiz', 'modalContentQuiz'])
  },
  methods: {
    ...mapMutations(['SET_MODALSHOWQUIZ', 'SET_MODALCONTENTQUIZ']),
    close () {
      this.SET_MODALSHOWQUIZ(false)
    },
    async enviarDatos () {
      this.alert = false
      this.modalContentQuiz.questions.forEach(ele => {
        if (!this.form[ele.question_code]) {
          this.alert = true
        }
      })
      if (!this.alert) {
        // eslint-disable-next-line no-console
        // console.log(this.form, JSON.stringify(this.form))
        let response = (
          await this.$http.post('/encuestas/requestEncuesta', this.form)
        ).data

        // eslint-disable-next-line no-console
        console.log('response', JSON.stringify(this.form))
        this.enviado = response
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
