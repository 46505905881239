<template>
	<v-app
		ref="sandbox"
		class="niu-dark"
		v-scroll="scrolling"
		style="background-color: #fff"
	>
		<template
			v-if="
				!['login', 'sesion', 'reproductor', 'play', 'alllive'].includes(
					$router.currentRoute.name,
				)
			"
		>
			<MenuXS
				v-if="$vuetify.breakpoint.name == 'xs'"
				:items="itemsMenu"
				:logos="logos"
				:premium="premium"
				:themeStyle="theme"
				@dialogObtenerFolio="dialogObtenerFolio"
			></MenuXS>
			<MenuMDSM
				v-else-if="
					$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
				"
				:items="itemsMenu"
				:logos="logos"
				:premium="premium"
				:themeStyle="theme"
				@dialogObtenerFolio="dialogObtenerFolio"
			>
			</MenuMDSM>

			<Menu
				v-else
				:items="itemsMenu"
				:logos="logos"
				:premium="premium"
				:themeStyle="theme"
				@dialogObtenerFolio="dialogObtenerFolio"
			>
			</Menu>
		</template>

		<div v-if="false">
			<menu-left-float
				:menu_main="menu_main"
				:membresia="membresia"
				:load_team="load_team"
				:primaryDrawer="primaryDrawerLeft"
				@menuFloatLeft="menuFloatLeft"
			></menu-left-float>
			<menu-right-float
				:primaryDrawer="primaryDrawerRight"
				@menuFloatRight="menuFloatRight"
			></menu-right-float>
			<v-app-bar
				color="primary"
				v-if="
					![
						'login',
						'sesion',
						'reproductor',
						'play',
						'free',
						'premium',
						'alllive',
						'ver_curso',
					].includes($router.currentRoute.name)
				"
				ref="app-bar"
				:clipped-right="primaryDrawerRight.clipped"
				app
				:height="heightMenu"
				style="z-index: 7"
			>
				<v-app-bar-nav-icon
					class="mr-7 hidden-md-and-up"
					@click.stop="menuFloatLeft"
					style="color: white !important"
				></v-app-bar-nav-icon>
				<v-toolbar
					:color="colorPrimario"
					elevation="0"
					:height="heightMenu"
					class="ml-0"
					style="z-index: 7"
				>
					<router-link to="#" replace>
						<!-- <v-img src="./assets/weblogo.png" size="164" ></v-img> -->
						<img
							src="https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none"
							alt
							:height="$vuetify.breakpoint.name == 'xs' ? '26px' : '32px'"
							class="mr-0"
						/>
						<img
							src="https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png"
							:height="$vuetify.breakpoint.name == 'xs' ? '26px' : '32px'"
							alt=""
						/>
					</router-link>

					<v-toolbar-items
						class="hidden-sm-and-down"
						:class="{
							'niu-transparent': niuMenuTransparent,
							'niu-gradient': !niuMenuTransparent,
						}"
					>
						<v-btn
							:to="`${
								load_team.team_code == 'elite' ||
								$storeNiu.getTextOrInt('LOAD_TEAM') == 'elite'
									? '/teams/'
									: '/team/'
							}${$storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code}`"
							class="transparent niu-not-border mr-0 pr-0 niu-btn-luisambiado"
							v-if="$storeNiu.getTextOrInt('LOAD_TEAM') || load_team.team_code"
						>
							{{
								$storeNiu.getTextOrInt('LOAD_TEAMTITLE') || load_team.team_title
							}}
						</v-btn>
						<v-btn
							:to="'/payperview'"
							replace
							class="transparent niu-not-border mr-0 pr-0 niu-btn-luisambiado"
							v-if="$storeNiu.getTextOrInt('LOAD_PAYPERVIEW') != -1"
						>
							Pay Per View
						</v-btn>
						<template v-for="item in menu_main">
							<v-btn
								:key="item.icon"
								:href="item.link"
								:target="item.target"
								replace
								class="transparent niu-not-border mr-0 pr-0 niu-btn-luisambiado"
								v-if="
									item.membresia &&
									item.membresia.length > 0 &&
									item.membresia.includes(membresia) &&
									item.externo == 1
								"
							>
								{{ item.title }}
								<span>
									<img :src="item.img" height="25px" />
								</span>
							</v-btn>

							<v-btn
								:key="item.icon"
								:to="item.link"
								:target="item.target"
								replace
								class="transparent niu-not-border mr-0 pr-0 niu-btn-luisambiado"
								v-if="
									item.membresia &&
									item.membresia.length > 0 &&
									item.membresia.includes(membresia) &&
									(item.externo == 0 || !item.externo)
								"
							>
								{{ item.title }}
								<span>
									<img :src="item.img" height="25px" />
								</span>
							</v-btn>
						</template>
						<!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Idioma
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title @click="changeIdioma(item.locale)">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
					</v-toolbar-items>
				</v-toolbar>
				<v-app-bar-nav-icon
					class="mr-7"
					@click.stop="menuFloatRight"
					style="color: white !important"
				>
					<v-avatar>
						<img
							:src="
								!dataUser
									? 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'
									: (dataUser.genero = 'hombre'
											? 'https://niufleex.com/wp-content/uploads/2022/01/businessman.png'
											: (dataUser.genero = 'mujer'
													? 'https://niufleex.com/wp-content/uploads/2022/01/businesswoman.png'
													: 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'))
							"
							alt="niufleex"
						/>
					</v-avatar>
				</v-app-bar-nav-icon>
			</v-app-bar>
		</div>

		<v-main
			id="page"
			:class="{
				'bg-login': $router.history.current.name == 'login',
				'mt-md-15 mt-xl-15':
					!['login', 'reproductor', 'play'].includes(
						$router.history.current.name,
					) && !premium,
				'mt-0': $router.history.current.name == 'reproductor',
				'mt-0': $router.history.current.name == 'play',
			}"
			style="position: relative"
			class="ma-0 pa-0 niu-top niu-bg-page"
			:style="{
				top:
					['xs', 'sm', 'md'].includes($vuetify.breakpoint.name) && premium
						? '70px'
						: '',
			}"
		>
			<v-container
				fluid
				:class="{
					'ma-0 pa-0': $router.currentRoute.name == 'sesion',
				}"
				class="fill-height"
				:style="{
					padding: premium ? '0 12px !important' : '12px !important',
				}"
			>
				<v-snackbar
					:top="alert.top"
					:bottom="!alert.top"
					:color="alert.type"
					v-model="alert.snackbar"
					:timeout="alert.timeout || 5000"
				>
					<div style="display: inline-block" v-html="alert.message"></div>
					<v-btn
						color="white"
						style="position: absolute; top: 2px"
						text
						@click="cerrarAlerta"
						v-if="alert.equis"
					>
						X
					</v-btn>
					<v-btn
						color="white"
						style="position: absolute; top: 15px"
						text
						@click="toggleMoreInfo"
						v-if="alert.moreinfo"
					>
						<v-icon>mdi-chevron-down</v-icon>
					</v-btn>
					<v-row v-if="alert.adicional">
						<v-col v-html="alert.msmAdicional"> </v-col>
					</v-row>
				</v-snackbar>
				<v-row
					align="center"
					justify="center"
					:class="{
						'ma-0': $router.currentRoute.name == 'sesion',
						'pa-0': $router.currentRoute.name == 'sesion',
					}"
					class="fill-height"
				>
					<v-col
						cols="12"
						class="fill-height ma-0 pa-0"
						:class="{
							'ma-0': $router.currentRoute.name == 'sesion',
							'pa-0': $router.currentRoute.name == 'sesion',
						}"
					>
						<router-view></router-view>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<section
			style="background-color: white; margin-top: 40px"
			v-if="
				$router.currentRoute.name != 'login' &&
				$router.currentRoute.name != 'reproductor' &&
				$router.currentRoute.name != 'play'
			"
		>
			<SoporteNiufleex></SoporteNiufleex>
		</section>
		<section
			:style="{
				backgroundColor: theme.backFooterUno,
			}"
			v-if="
				$router.currentRoute.name != 'login' &&
				$router.currentRoute.name != 'reproductor' &&
				$router.currentRoute.name != 'play'
			"
		>
			<FooterUno></FooterUno>
		</section>
		<section
			:style="{
				backgroundColor: theme.backFooterDos,
			}"
			v-if="
				$router.currentRoute.name != 'login' &&
				$router.currentRoute.name != 'reproductor' &&
				$router.currentRoute.name != 'play'
			"
		>
			<FooterDos></FooterDos>
		</section>
		<!--<bottom
			v-if="
				($vuetify.breakpoint.name == 'xs' ||
					$vuetify.breakpoint.name == 'sm') &&
				$router.currentRoute.name != 'login' &&
				$router.currentRoute.name != 'reproductor' &&
				$router.currentRoute.name != 'play'
			"
			:menuBottom="menu_main"
			:membresiaBottom="membresia"
		></bottom>-->
		<LoadingHTTP :loading.sync="loading"></LoadingHTTP>
		<ModalQuiz></ModalQuiz>
		<ModalConfirmEmail></ModalConfirmEmail>
		<ModalConfirmTeam></ModalConfirmTeam>
		<v-dialog
			v-model="dialogOrdenes"
			transition="dialog-top-transition"
			max-width="900"
		>
			<v-card>
				<v-toolbar color="primary" dark>Listado</v-toolbar>
				<v-card-text>
					<ordenes></ordenes>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn text @click="dialogOrdenes = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="showDialogObtenerFolio"
			transition="dialog-top-transition"
			max-width="900"
		>
			<v-card>
				<v-toolbar color="primary" dark>
					Sincronizar con tu cuenta Teoma
				</v-toolbar>
				<v-card-text>
					<v-row justify="center">
						<v-col cols="12" md="5">
							<v-text-field
								label
								placeholder="Usuario"
								color="#ffffff"
								v-model="form.username"
								required
							></v-text-field>
							<v-text-field
								label
								placeholder="Contraseña"
								v-model="form.password"
								:append-icon="verpass ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (verpass = !verpass)"
								:type="!verpass ? 'password' : 'text'"
								required
							></v-text-field>
							<v-btn
								:loading="showProgress"
								block
								class="niu-background-principal-1 mr-4 mt-3 niu-input-placeholder rounded-lg rounded-xl rounded niu-luchoambiado"
								type="submit"
								@click="conectarTeoma"
							>
								Conectar
							</v-btn>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" md="5">
							<ul style="list-style: none">
								<li v-for="(item, key) of logingestor" :key="key">
									{{ namelogingestor[key] }}: {{ item }}
								</li>
							</ul>
							<v-btn @click="asociarTeoma" :loading="showProgress">
								Asociar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn text @click="showDialogObtenerFolio = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
	/* eslint-disable no-empty */
	import menuLeftFloat from './components/menu/menuLeftFloat.vue';
	import menuRightFloat from './components/menu/menuRightFloat.vue';
	import bottom from './components/menu/bottom_navigation.vue';
	import LoadingHTTP from './components/shared/loading.vue';
	import ModalQuiz from './components/shared/ModalQuiz.vue';
	import ModalConfirmEmail from './components/shared/ModalConfirmEmail.vue';
	import ModalConfirmTeam from './components/shared/ModalConfirmTeam.vue';
	import SoporteNiufleex from './components/shared/Footer/SoporteNiufleex.vue';
	import FooterUno from './components/shared/Footer/FooterUno.vue';
	import FooterDos from './components/shared/Footer/FooterDos.vue';
	// import axios from "axios";
	import Ordenes from './views/Ordenes.vue';
	//SECCION MENU
	import Menu from './components/shared/Menu/MenuLG.vue';
	import MenuMDSM from './components/shared/Menu/MenuSM_MD.vue';
	import MenuXS from './components/shared/Menu/MenuXS.vue';
	import { mapState, mapMutations } from 'vuex';
	export default {
		name: 'App',
		components: {
			menuLeftFloat,
			bottom,
			LoadingHTTP,
			ModalQuiz,
			ModalConfirmEmail,
			ModalConfirmTeam,
			menuRightFloat,
			SoporteNiufleex,
			FooterUno,
			FooterDos,
			Menu,
			MenuMDSM,
			MenuXS,
			Ordenes,
		},

		mounted() {
			// eslint-disable-next-line no-console
			// console.log(this.$router.options.routes.interfaz);
			this.SET_RUTA(this.$router.currentRoute.name);
		},
		methods: {
			...mapMutations(['SET_RUTA', 'SET_ALERT']),
			changeIdioma(locale) {
				this.$storeNiu.set('locale', locale);
			},
			toggleMoreInfo() {
				this.$store.commit('SET_ALERT', {
					...this.$store.state.alert,
					adicional: !this.$store.state.alert.adicional,
				});
			},
			disableDeveloperTools() {
				window.addEventListener('keydown', function (e) {
					if (
						e.keyCode === 123 ||
						((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 73)
					) {
						e.preventDefault();
					}
				});
				let checkStatus;
				let element = new Image();
				Object.defineProperty(element, 'id', {
					get: function () {
						checkStatus = 'on';
						throw new Error('Dev tools checker');
					},
				});
				requestAnimationFrame(function check() {
					checkStatus = 'off';
					// eslint-disable-next-line no-console
					console.dir(element);
					if ('on' === checkStatus) {
						document.body.parentNode.removeChild(document.body);
						document.head.parentNode.removeChild(document.head);
						setTimeout(function () {
							// eslint-disable-next-line
							while (true) {
								eval('debugger');
							}
						}, 100);
					} else {
						requestAnimationFrame(check);
					}
				});
			},
			scrolling(e) {
				this.offsetTop = e.target.scrollingElement.scrollTop;
			},
			menuFloatRight() {
				this.primaryDrawerRight.model = !this.primaryDrawerRight.model;
			},
			menuFloatLeft() {
				this.primaryDrawerLeft.model = !this.primaryDrawerLeft.model;
			},
			cerrarAlerta() {
				this.$store.commit('alert', {
					type: '',
					message: '',
					snackbar: false,
				});
			},
			dialogObtenerFolio(show) {
				// eslint-disable-next-line no-console
				console.log('show');
				this.showDialogObtenerFolio = show;
			},
			async conectarTeoma() {
				this.showProgress = true;
				try {
					let result = (
						await this.$http.post('/users/loginGestorServicio', this.form)
					).data;

					if (result) {
						this.logingestor = result;
						// eslint-disable-next-line no-console
						console.log(result);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error.response.data.message);
					let message = 'Error';
					switch (error.response.data.message) {
						case 'utilizado':
							message = 'Error ID Teoma utilizado';
							break;
					}

					this.SET_ALERT({
						message: message,
						type: 'error',
						snackbar: true,
					});
				} finally {
					this.showProgress = false;
				}
			},
			async asociarTeoma() {
				this.showProgress = true;
				try {
					let result = (
						await this.$http.post(
							'/users/actualizarCustomerId',
							this.logingestor,
						)
					).data;

					if (result) {
						this.logingestor = result;
						// eslint-disable-next-line no-console
						console.log(result);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(...error.message);

					// eslint-disable-next-line no-console
					console.log(error.response.data.message);
					let message = 'Error';
					switch (error.response.data.message) {
						case 'utilizado':
							message = 'Error ID Teoma utilizado';
							break;
					}

					this.SET_ALERT({
						message: message,
						type: 'error',
						snackbar: true,
					});
				} finally {
					this.showProgress = false;
				}
			},
		},
		updated() {
			// eslint-disable-next-line no-console
			// console.log('paso app.vue')
			let premium =
				this.$storeNiu.getObjectValue('data') &&
				this.$storeNiu.getObjectValue('data').membership_id == 2;
			this.$store.commit('SET_PREMIUM', premium);
		},
		async created() {
			this.localhost = window.location.hostname == 'localhost' ? true : false;

			if (process.env.NODE_ENV == 'development') {
				// baseURL = '//api.niufleex.com'
				this.$http.defaults.baseURL = `//${process.env.VUE_APP_API}`;
				// this.$http.defaults.baseURL = "//192.168.1.89:5000";
				// this.$http.defaults.baseURL = "//3.209.251.126:5000";
				// this.$http.defaults.baseURL = "//api-ux-test.niufleex.com";
				// this.$http.defaults.baseURL = '//app.niufleex.net'
			} else {
				let test = process.env.VUE_APP_TEST == 'false' ? false : true;
				if (test) {
					this.$http.defaults.baseURL = '//app.niufleex.net';
				} else {
					this.$http.defaults.baseURL = `//${process.env.VUE_APP_API}`;
				}
			}

			// this.$findLocalIp().then(ip => {
			//   //eslint-disable-next-line
			//   if (Array.isArray(ip)) {
			//     this.ip = ip[0]
			//   } else {
			//     this.ip = ip
			//   }
			//   this.$storeNiu.set('privada', this.ip)
			// })
			// this.$storeNiu.set('navegador', this.$getBrowserInfo())

			this.$http.interceptors.request.use((request) => {
				request.headers['Authorization'] = this.$storeNiu.getTextOrInt('token')
					? 'Bearer ' + this.$storeNiu.getTextOrInt('token')
					: false;

				request.headers['Access-Control-Allow-Origin'] = window.location.origin;
				// request.headers['Access-Control-Allow-Origin']= 'http://127.0.0.1:3000'
				request.headers['Access-Control-Allow-Methods'] = '*';
				request.headers['Access-Control-Allow-Headers'] = '*';

				return request;
			});
		},
		watch: {
			offsetTop() {
				if (this.offsetTop > 30) {
					this.niuMenuTransparent = false;
				} else {
					this.niuMenuTransparent = true;
				}
			},
		},
		computed: {
			...mapState([
				'membresia',
				'loading',
				'load_team',
				'ruta_pathname',
				'premium',
				'theme',
			]),
			async validate_team() {
				let data = this.$storeNiu.getObjectValue('data');
				// eslint-disable-next-line no-console
				// console.log(
				// 	this.$route.query.validate_team,
				// 	data.email_verified,
				// 	this.$route.query.validate_team && data.team_guid == ""
				// );
				if (this.$route.query.validate_team && data.team_guid == '') {
					this.$store.commit('SET_MODALSHOWCONFIRMTEAM', true);
				} else {
					this.$store.commit('SET_MODALSHOWCONFIRMTEAM', false);
				}
				return '';
			},
			async verified_email() {
				let data = this.$storeNiu.getObjectValue('data');
				// eslint-disable-next-line no-console
				// console.log(
				// 	this.$route.query.verified_email,
				// 	data.email_verified,
				// 	this.$route.query.verified_email && data.email_verified == 0
				// );
				if (this.$route.query.verified_email && data.email_verified == 0) {
					this.$store.commit('SET_MODALSHOWCONFIRM', true);
					let config = {
						headers: {
							Authorization: `Bearer ${
								this.$storeNiu.getTextOrInt('token') || this.token
							}`,
						},
					};
					let respuesta = (
						await this.$http.post(
							'/users/verified',
							{
								guid_user_email_request: this.$route.query.verified_email,
							},
							config,
						)
					).data;

					data.email_verified = respuesta ? 1 : 0;
					this.$storeNiu.set('data', data);
				} else {
					this.$store.commit('SET_MODALSHOWCONFIRM', false);
				}
				return '';
			},
			heightMenu() {
				switch (this.$vuetify.breakpoint.name) {
					case 'xs':
						return '85px';
					case 'sm':
						return '85px';
					case 'md':
						return '96px';
					case 'lg':
						return '96px';
					default:
						return '96px';
				}
			},
			alert() {
				return this.$store.state.alert;
			},
			colorPrimario() {
				switch (this.membresia) {
					case 2:
						this.$vuetify.theme.dark = true;
						return this.$vuetify.theme.themes.dark.primary;
					default:
						this.$vuetify.theme.dark = false;
						return this.$vuetify.theme.themes.light.primary;
				}
			},
			dataUser() {
				return this.$storeNiu.getObjectValue('data');
			},
		},
		data: () => {
			return {
				namelogingestor: {
					customerID: 'Folio',
					firstName: 'Nombres',
					lastName: 'Apellidos',
					customerKey: '',
				},
				logingestor: {},
				showProgress: false,
				verpass: false,
				form: {
					username: '',
					password: '',
				},
				showDialogObtenerFolio: false,
				dialogOrdenes: false,
				localhost: false,
				server: '',
				offsetTop: 0,
				niuMenuTransparent: true,
				primaryDrawerLeft: {
					model: null,
					clipped: false,
					floating: false,
					mini: false,
				},
				primaryDrawerRight: {
					model: null,
					clipped: false,
					floating: false,
					mini: false,
				},
				footer: {
					inset: false,
					fixed: false,
					absolute: false,
				},
				// items: [
				// 	{
				// 		locale: "es",
				// 		title: "Español",
				// 	},
				// 	{
				// 		locale: "en",
				// 		title: "Inglés",
				// 	},
				// ],
				itemsMenu: [
					{
						boton: 'Explora Free',
						link: '/explora/free',
						target: '_self',
						interna: 0,
						subitems: [],
						membresia: [0, 1, 2],
					},
					{
						boton: 'Explora Premium',
						link: '/explora/premium',
						target: '_self',
						interna: 0,
						subitems: [],
						membresia: [2],
					},
					{
						boton: 'Cursos',
						link: '/explora/free#cursos',
						target: '_self',
						interna: 0,
						membresia: [0, 1],
						subitems: [],
					},
					{
						boton: 'Cursos',
						link: '/explora/premium#cursos',
						target: '_self',
						interna: 0,
						membresia: [2],
						subitems: [],
					},
					{
						boton: 'Cursos',
						link: '#',
						target: '_self',
						interna: 0,
						membresia: [],
						subitems: [
							{
								boton: 'Área de Conocimiento 1',
								link: 'https://niufleex.com',
								target: '_blank',
								interna: 0,
							},
							{
								boton: 'Área de Conocimiento 2',
								link: 'https://niufleex.com',
								target: '_blank',
								interna: 0,
							},
							{
								boton: 'Área de Conocimiento 3',
								link: 'https://niufleex.com',
								target: '_blank',
								interna: 0,
							},
						],
					},
					{
						boton: 'Especialistas',
						link: '/explora/free#especialistas',
						target: '_self',
						interna: 1,
						membresia: [0, 1],
						subitems: [],
					},
					{
						boton: 'Especialistas',
						link: '/explora/premium#especialistas',
						target: '_self',
						interna: 1,
						membresia: [2],
						subitems: [],
					},
					{
						boton: 'Live',
						link: '/live',
						target: '_self',
						interna: 1,
						membresia: [0, 1, 2],
						subitems: [],
					},
				],
				logos: {
					logo1: {
						src: 'https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none',
						link: '/explora/free',
						target: '_self',
					},
					logo2: {
						src: 'https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png',
						link: '/explora/free',
						target: '_self',
					},

					logo3: {
						src: 'https://niufleex.com/wp-content/uploads/2020/11/N-premium.png',
						link: '/explora/premium',
						target: '_self',
					},
					avatar: {
						src: '',
					},
				},
				menu_main: [
					// {
					// 	title: "Explora Pro",
					// 	link: "/newexplora-pro",
					// 	img: require("./assets/niufleex_pro.png"),
					// 	membresia: [1, 2],
					// 	title_little: "Pro",
					// },

					{
						title: 'Explora Free',
						link: '/explora-free',
						img: require('./assets/niufleex_pro.png'),
						membresia: [0, 1, 2],
						title_little: 'Free',
					},
					{
						title: 'Explora Premium',
						link: '/newexplora',
						img: require('./assets/logo_premium_150x150.png'),
						membresia: [2],
						title_little: 'Premium',
					},
					// {
					// 	title: "Holter",
					// 	link: "/holter",
					// 	img: require("./assets/iconos/holter.png"),
					// 	membresia: [1, 2],
					// 	title_little: "Holter",
					// },
					{
						title: 'Mis rutas',
						link: '/universo',
						img: '',
						membresia: [1, 2],
						title_little: 'Áreas',
					},
					{
						title: 'Mi aprendizaje',
						link: '/miaprendizaje',
						img: require('./assets/iconos/rutas.png'),
						membresia: [1, 2],
						title_little: 'Mis Rutas',
					},
					{
						title: 'Live',
						link: '/live',
						img: '',
						membresia: [1, 2],
						title_little: 'Live',
					},
					{
						title: '',
						link: 'https://backoffice.teomacorp.com/login?ReturnUrl=%2f',
						target: '_blank',
						externo: 1,
						img: require('./assets/iconos/comprarahora.svg'),
						membresia: [0],
						title_little: 'Compra Ahora',
					},
				],
				scrolled: false,
				icons: [
					{ icon: 'mdi-facebook', link: 'https://www.facebook.com/niufleex' },
					{
						icon: 'mdi-instagram',
						link: 'https://www.instagram.com/niufleex/',
					},
					{
						icon: 'mdi-youtube',
						link: 'https://www.youtube.com/channel/UCz_IAx5oS2B9EWRw4nOVpEQ',
					},
				],
				// XMLHttpRequest: null
			};
		},
	};
</script>
<style lang="scss">
	$fondo: white;
	$bg_page: #f5f5f5;

	.v-btn--active {
		color: #7a577d !important;
	}

	.niu-not-border::before {
		background-color: transparent !important;
	}
	.niu-bg-page {
		background-color: $bg_page !important;
	}
	.v-application .black {
		background-color: $fondo !important;
		border-color: $fondo !important;
	}
	.v-application .rojo {
		background-color: red !important;
		border-color: red !important;
	}
	.niu-transparent.v-btn {
		background-color: transparent !important;
		border: 0px !important;
		box-shadow: 0px 0px 0px #000;
	}
	.niu-transparent.text-shadow-black-2.v-btn .v-icon {
		text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000,
			1px -1px 1px #000 !important;
	}

	.niu-back-white {
		background-color: white;
	}
	.theme--dark.v-sheet.niu-transparent {
		background-color: $fondo;
		border: 0;
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0),
			0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
	}

	.bg-login {
		background: url('./assets/fondos/login.jpg') no-repeat center center fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.niu-top {
		top: 0;
	}
	.theme--dark.v-footer.niu-dark {
		background-color: $fondo;
	}
	.niu-titulo {
		background-color: $fondo;
	}
	.niu-not-border {
		border-width: 0 !important;
		box-shadow: 0px 0px 0px 0px transparent !important;
	}

	.niu-hover {
		border-radius: 8px;
		border: 3px solid transparent;
		border-radius: 8px;
	}
	.niu-border-emprendimientonegocio-dark {
		border-color: #ffae04;
	}
	.niu-border-emprendimientonegocio-light {
		border-color: #ffe800;
	}
	.niu-border-liderazgo-dark {
		border-color: #f64600;
	}
	.niu-border-liderazgo-light {
		border-color: #f39d00;
	}
	.niu-border-desarrollofisico-dark {
		border-color: #770005;
	}
	.niu-border-desarrollofisico-light {
		border-color: #f40000;
	}
	.niu-border-desarrollopersonal-dark {
		border-color: #2d6b00;
	}
	.niu-border-desarrollopersonal-light {
		border-color: #2ae82d;
	}
	.niu-border-conocimientotecnico-dark {
		border-color: #11b29c;
	}
	.niu-border-conocimientotecnico-light {
		border-color: #3effe8;
	}
	.niu-border-comunicacion-dark {
		border-color: #354cf9;
	}
	.niu-border-comunicacion-light {
		border-color: #0540f2;
	}
	.niu-background-gradient-emprendimientonegocio {
		background: rgb(255, 174, 4);
		background: linear-gradient(45deg, rgba(255, 174, 4, 1) 0%, #ffb92e 100%);
	}
	.niu-background-gradient-liderazgo {
		background: rgb(246, 70, 0);
		background: linear-gradient(45deg, rgba(246, 70, 0, 1) 0%, #c10000 100%);
	}
	.niu-background-gradient-desarrollofisico {
		background: #fc3a72;
		background: linear-gradient(
			45deg,
			rgba(119, 0, 5, 1) 0%,
			rgba(244, 0, 0, 1) 100%
		);
	}
	.niu-background-gradient-desarrollopersonal {
		background: #8ac445;
		background: linear-gradient(
			45deg,
			rgba(45, 107, 0, 1) 0%,
			rgba(42, 232, 45, 1) 100%
		);
	}
	.niu-background-gradient-conocimientotecnico {
		background: rgb(17, 178, 156);
		background: linear-gradient(45deg, #40ccb1 0%, rgba(62, 255, 232, 1) 100%);
	}
	.niu-background-gradient-comunicacion {
		background: rgb(3, 0, 42);
		background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
	}

	.niu-background-gradient-emprendimientonegocio {
		background: #ffb92e;
		background: linear-gradient(45deg, #ffb92e 0%, rgba(255, 174, 4, 1) 100%);
	}
	.niu-background-gradient-emprendimientonegocio_hover:hover {
		background: #ffb92e;
		background: linear-gradient(45deg, #ffb92e 0%, rgba(255, 174, 4, 1) 100%);
	}
	.niu-background-gradient-liderazgo {
		background: #c10000;
		background: linear-gradient(45deg, #c10000 0%, rgba(246, 70, 0, 1) 100%);
	}
	.niu-background-gradient-liderazgo_hover:hover {
		background: #c10000;
		background: linear-gradient(45deg, #c10000 0%, rgba(246, 70, 0, 1) 100%);
	}
	.niu-background-gradient-desarrollofisico {
		background: rgba(244, 0, 0, 1);
		background: linear-gradient(
			45deg,
			rgba(244, 0, 0, 1) 0%,
			rgba(119, 0, 5, 1) 100%
		);
	}
	.niu-background-gradient-desarrollofisico_hover:hover {
		background: rgba(244, 0, 0, 1);
		background: linear-gradient(
			45deg,
			rgba(244, 0, 0, 1) 0%,
			rgba(119, 0, 5, 1) 100%
		);
	}
	.niu-background-gradient-desarrollopersonal {
		background: rgba(42, 232, 45, 1);
		background: linear-gradient(
			45deg,
			rgba(42, 232, 45, 1) 0%,
			rgba(45, 107, 0, 1) 100%
		);
	}
	.niu-background-gradient-desarrollopersonal_hover:hover {
		background: rgba(42, 232, 45, 1);
		background: linear-gradient(
			45deg,
			rgba(42, 232, 45, 1) 0%,
			rgba(45, 107, 0, 1) 100%
		);
	}
	.niu-background-gradient-conocimientotecnico {
		background: rgba(62, 255, 232, 1);
		background: linear-gradient(45deg, rgba(62, 255, 232, 1) 0%, #40ccb1 100%);
	}
	.niu-background-gradient-conocimientotecnico_hover:hover {
		background: rgba(62, 255, 232, 1);
		background: linear-gradient(45deg, rgba(62, 255, 232, 1) 0%, #40ccb1 100%);
	}
	.niu-background-gradient-comunicacion {
		background: rgb(3, 0, 42);
		background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
	}
	.niu-background-gradient-comunicacion_hover:hover {
		background: rgb(3, 0, 42);
		background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
	}
	.niu-background-gradient-niufleex-1,
	.niu-background-gradient-niufleex-1_hover:hover {
		background: #590d53;
		background: linear-gradient(45deg, #590d53 0%, #a52882 100%);
	}

	.niu-btn-luisambiado {
		color: white !important;
		letter-spacing: normal !important;
		font-size: 1.08rem !important;
		text-transform: none !important;
	}
	.ellipsis {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.block-ellipsis {
		display: block;
		display: -webkit-box;
		max-width: 100%;
		height: 43px;
		margin: 0 auto;
		font-size: 14px;
		line-height: 1;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.block-ellipsis3 {
		display: block;
		display: -webkit-box;
		max-width: 100%;
		margin: 0 auto;
		font-size: 14px;
		line-height: 1;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.v-window__next .v-btn span i {
		font-size: 36px !important;
	}
	.v-window__prev .v-btn span i {
		font-size: 36px !important;
	}
</style>
